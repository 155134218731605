import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./assets/scss/index.scss";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("./service-worker.js")
//       .then((registration) => {
//         console.log(
//           "Service Worker registered with scope:",
//           registration.scope
//         );
//       })
//       .catch((err) => {
//         console.error("Service Worker registration failed:", err);
//       });
//   });
// }

// Render app in `#root` element
ReactDOM.render(<App />, document.getElementById("root"));
