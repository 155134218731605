import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import theme from "./themeReducer";
import organisation from "../ducks/organisation";
import underwriter from "../ducks/underwriter";
import user from "../ducks/user";
import scheme from "../ducks/scheme";
import policy from "../ducks/policy";
import mediaClaims from "../ducks/media_claims";
import mediaOrg from "../ducks/media_org";
import mediaPolicy from "../ducks/media_policy";
import notifications from "../ducks/notifications";
import premiums from "../ducks/premiums";
import reports from "../ducks/reports";
import audit from "../ducks/audit";

import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  theme,
  toastr,
  organisation,
  underwriter,
  user,
  scheme,
  policy,
  mediaClaims,
  mediaOrg,
  mediaPolicy,
  notifications,
  premiums,
  reports,
  audit,
});
