import axios from "axios";

export function requestCreatePolicy(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/policy/application`,
    data,
  });
}

export function requestGetPolicyById(data) {
  console.log("requestGetPolicyById");
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/policy/application/${data}`,
  });
}

export function requestGetPoliciesBySchemeId(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/policy/scheme/${data}`,
  });
}

export function requestUpdatePolicy(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/policy/application/${data.org}/${data.user}`,
    data,
  });
}

export function requestDeletePolicy(data) {
  return axios.request({
    method: "delete",
    url: `${process.env.REACT_APP_DELETE_ENDPOINT}?type=policy&id=${data}`,
  });
}

export function requestUpdatePolicyStatus(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/policy/status`,
    data,
  });
}
