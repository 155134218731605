import React from "react";
import { UncontrolledAlert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faBan,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

export function SuccessAlert(props) {
  const { isOpen, toggle, message } = props;
  return (
    <UncontrolledAlert isOpen={isOpen} toggle={toggle} color={"success"}>
      <div className="alert-icon">
        <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
      </div>
      <div className="alert-message">
        <strong>{message}</strong>
      </div>
    </UncontrolledAlert>
  );
}

export function WarningAlert(props) {
  const { isOpen, toggle, message } = props;
  return (
    <UncontrolledAlert isOpen={isOpen} toggle={toggle} color={"warning"}>
      <div className="alert-icon">
        <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
      </div>
      <div className="alert-message">
        <strong>{message}</strong>
      </div>
    </UncontrolledAlert>
  );
}

export function FailureAlert(props) {
  const { isOpen, toggle, message } = props;
  return (
    <UncontrolledAlert isOpen={isOpen} toggle={toggle} color={"danger"}>
      <div className="alert-icon">
        <FontAwesomeIcon icon={faBan} fixedWidth />
      </div>
      <div className="alert-message">
        <strong>{message}</strong>
      </div>
    </UncontrolledAlert>
  );
}

export function InfoAlert(props) {
  const { isOpen, toggle, message } = props;
  return (
    <UncontrolledAlert isOpen={isOpen} toggle={toggle} color={"primary"}>
      <div className="alert-icon">
        <FontAwesomeIcon icon="fa-solid fa-circle-info" fixedWidth />
      </div>
      <div className="alert-message">
        <strong>{message}</strong>
      </div>
    </UncontrolledAlert>
  );
}
