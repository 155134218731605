import React from "react";
import { Link } from "react-router-dom";
import visualdata from "../assets/illustrations/Visualdata.gif";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const EmptyState = ({ heading, subheading, path, btntext, action }) => (
  <>
    <Row>
      <Col
        md="12"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={visualdata}
          alt=""
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "40%",
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col md="12">
        <h3
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#3e4676",
          }}
        >
          {heading}
        </h3>
      </Col>
    </Row>
    <Row>
      <Col md="12">
        <h5
          className="card-subtitle text-muted"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "80%",
            marginLeft: "10%",
          }}
        >
          {subheading}
        </h5>
      </Col>
    </Row>
    {path !== undefined && btntext !== undefined ? (
      <Row>
        <Col
          md="12"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {action === undefined ? (
            <Link to={path}>
              <Button
                size="lg"
                color="primary"
                style={{ marginTop: 40, marginBottom: 40 }}
              >
                <FontAwesomeIcon icon={faPlus} /> {btntext}
              </Button>
            </Link>
          ) : (
            <Button
              size="lg"
              color="primary"
              style={{ marginTop: 40, marginBottom: 40 }}
              onClick={() => action()}
            >
              <FontAwesomeIcon icon={faPlus} /> {btntext}
            </Button>
          )}
        </Col>
      </Row>
    ) : (
      <></>
    )}
  </>
);

export default EmptyState;
