import { put, call } from "redux-saga/effects";
import {
  requestCreateOrganisation,
  requestGetOrganisation,
  requestGetOrganisationsByOrgId,
  requestUpdateOrganisation,
} from "../requests/organisation";
import {
  createOrgFailure,
  createOrgSuccess,
  getOrgsFailure,
  getOrgsSuccess,
  getOrgFailure,
  getOrgSuccess,
  getMyOrgFailure,
  getMyOrgSuccess,
  updateOrgSuccess,
  updateOrgFailure,
  updateMyOrgSuccess,
  updateMyOrgFailure,
} from "../../ducks/organisation";

export function* handleCreateOrganisation(action) {
  try {
    const response = yield call(requestCreateOrganisation, action.payload);
    const { data } = response;
    yield put(createOrgSuccess({ organisation: data }));
  } catch (err) {
    yield put(createOrgFailure(err.message));
  }
}

export function* handleGetOrganisation(action) {
  try {
    const response = yield call(requestGetOrganisation, action.payload);
    const { data } = response;
    yield put(getOrgSuccess(data.response));
  } catch (err) {
    yield put(getOrgFailure(err.message));
  }
}

export function* handleGetOrganisationsByOrgId(action) {
  try {
    const response = yield call(requestGetOrganisationsByOrgId, action.id);
    const { data } = response;
    yield put(getOrgsSuccess(data.body.response));
  } catch (err) {
    yield put(getOrgsFailure(err.message));
  }
}

export function* handleGetMyOrganisation(action) {
  try {
    const response = yield call(requestGetOrganisation, action.payload.id);
    const { data } = response;
    yield put(getMyOrgSuccess(data.response));
  } catch (err) {
    yield put(getMyOrgFailure(err.message));
  }
}

export function* handleUpdateOrganisation(action) {
  try {
    const response = yield call(requestUpdateOrganisation, action.payload);
    const { data } = response;
    yield put(updateOrgSuccess(data.response));
  } catch (err) {
    yield put(updateOrgFailure(err.message));
  }
}

export function* handleUpdateMyOrganisation(action) {
  try {
    const response = yield call(requestUpdateOrganisation, action.payload);
    const { data } = response;
    yield put(updateMyOrgSuccess(data.response));
  } catch (err) {
    yield put(updateMyOrgFailure(err.message));
  }
}
