export const GET_AUDIT_LOGS_REQUESTED = "GET_AUDIT_LOGS_REQUESTED";
export const GET_AUDIT_LOGS_SUCCESS = "GET_AUDIT_LOGS_SUCCESS";
export const GET_FAILURE = "GET_FAILURE";

export const getAuditLogs = (orgId) => ({
  type: GET_AUDIT_LOGS_REQUESTED,
  orgId,
});

export const getAuditLogsSuccess = (payload) => ({
  type: GET_AUDIT_LOGS_SUCCESS,
  payload,
});

export const getFailure = (error) => ({
  type: GET_FAILURE,
  error,
});

const initialState = {
  auditLog: undefined,
  auditLogs: undefined,
  loading: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AUDIT_LOGS_REQUESTED:
      return { ...state, loading: true };
    case GET_AUDIT_LOGS_SUCCESS:
      return { ...state, auditLogs: action.payload, loading: false };
    case GET_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};
