import { put, call } from "redux-saga/effects";
import {
  requestSendSMSNotification,
  requestSendCustomEmailNotification,
  requestGetEmailTemplates,
  requestCreateNotification,
  requestGetPolicyNotifications,
  requestGetSmsReport,
  requestGetEmailReport,
  requestGetNotificationsInApp,
  requestSendPDFEmailNotification,
  requestSendCSVEmailNotification,
} from "../requests/notifications";
import {
  createSendSMSNotificationSuccess,
  createSendEmailNotificationSuccess,
  createNotificationSuccess,
  getEmailTemplatesSuccess,
  getPolicyNotificationsSuccess,
  getError,
  getSmsReportSuccess,
  getEmailReportSuccess,
  getNotificationsInAppSuccess,
  sendPDFEmailSuccess,
  sendCSVEmailSuccess,
} from "../../ducks/notifications";

export function* handleSendSMSNotification(action) {
  try {
    const response = yield call(requestSendSMSNotification, action.payload);
    const { data } = response;
    yield put(createSendSMSNotificationSuccess(data.response));
  } catch (err) {
    yield put(getError({ error: err.message }));
  }
}

export function* handleSendEmailNotification(action) {
  try {
    const response = yield call(
      requestSendCustomEmailNotification,
      action.payload
    );
    const { data } = response;
    yield put(createSendEmailNotificationSuccess(data.response));
  } catch (err) {
    yield put(getError({ error: err }));
  }
}

export function* handleGetEmailTemplates(action) {
  try {
    const response = yield call(requestGetEmailTemplates, action.payload);
    const { data } = response;
    yield put(getEmailTemplatesSuccess(data.templates));
  } catch (err) {
    yield put(getError({ error: err }));
  }
}

export function* handleCreateNotification(action) {
  try {
    const response = yield call(requestCreateNotification, action.payload);
    const { data } = response;
    yield put(createNotificationSuccess(data.response));
  } catch (err) {
    yield put(getError({ error: err }));
  }
}

export function* handleGetPolicyNotifications(action) {
  try {
    const response = yield call(requestGetPolicyNotifications, action.payload);
    const { data } = response;
    yield put(getPolicyNotificationsSuccess(data.response));
  } catch (err) {
    yield put(getError({ error: err }));
  }
}

export function* handleGetInAppNotifications(action) {
  try {
    const response = yield call(requestGetNotificationsInApp, action.payload);
    const { data } = response;
    yield put(getNotificationsInAppSuccess(data.response));
  } catch (err) {
    yield put(getError({ error: err }));
  }
}

export function* handleGetSmsReport(action) {
  try {
    const response = yield call(requestGetSmsReport, action.payload);
    const { data } = response;
    yield put(getSmsReportSuccess(data.events));
  } catch (err) {
    yield put(getError({ error: err }));
  }
}

export function* handleGetEmailReport(action) {
  try {
    const response = yield call(requestGetEmailReport, action.payload);
    console.log("response: ", response);
    const { data } = response;
    yield put(getEmailReportSuccess(data));
  } catch (err) {
    yield put(getError({ error: err }));
  }
}

export function* handleSendPDFEmailNotification(action) {
  try {
    const response = yield call(
      requestSendPDFEmailNotification,
      action.payload
    );
    console.log("response: ", response);
    const { data } = response;
    yield put(sendPDFEmailSuccess(data));
  } catch (err) {
    yield put(getError({ error: err }));
  }
}

export function* handleSendCSVEmailNotification(action) {
  try {
    const response = yield call(
      requestSendCSVEmailNotification,
      action.payload
    );
    console.log("response: ", response);
    const { data } = response;
    yield put(sendCSVEmailSuccess(data));
  } catch (err) {
    yield put(getError({ error: err }));
  }
}
