import axios from "axios";

export function requestCreateScheme(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/scheme`,
    data,
  });
}

export function requestGetScheme(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/scheme/${data}`,
  });
}

export function requestGetSchemesByOrgId(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/schemes/${data}`,
  });
}

export function requestUpdateScheme(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/scheme`,
    data,
  });
}

export function requestDeleteScheme(data) {
  return axios.request({
    method: "delete",
    url: `${process.env.REACT_APP_DELETE_ENDPOINT}?type=scheme&id=${data.id}`,
  });
}
