import axios from "axios";

export function requestCreateOrganisation(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/organization/create`,
    data,
  });
}

export function requestGetOrganisation(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/organization/${data}`,
  });
}

// Return all organizations under super organisation
export function requestGetOrganisationsByOrgId(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/organization/list/${data}`,
  });
}

export function requestUpdateOrganisation(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/organization/update`,
    data,
  });
}
