export const CREATE_SCHEME_REQUESTED = "CREATE_SCHEME_REQUESTED";
export const CREATE_SCHEME_SUCCESS = "CREATE_SCHEME_SUCCESS";
export const CREATE_SCHEME_FALIURE = "CREATE_SCHEME_FALIURE";
export const CREATE_SCHEME_RATES_REQUESTED = "CREATE_SCHEME_RATES_REQUESTED";
export const CREATE_SCHEME_RATES_SUCCESS = "CREATE_SCHEME_RATES_SUCCESS";
export const CREATE_SCHEME_RATES_FALIURE = "CREATE_SCHEME_RATES_FALIURE";
export const UPDATE_SCHEME_REQUESTED = "UPDATE_SCHEME_REQUESTED";
export const UPDATE_SCHEME_SUCCESS = "UPDATE_SCHEME_SUCCESS";
export const UPDATE_SCHEME_FAILURE = "UPDATE_SCHEME_FAILURE";
export const UPDATE_REDUX_SCHEME_REQUESTED = "UPDATE_REDUX_SCHEME_REQUESTED";
export const GET_SCHEME_REQUESTED = "GET_SCHEME_REQUESTED";
export const GET_SCHEME_SUCCESS = "GET_SCHEME_SUCCESS";
export const GET_SCHEME_FALIURE = "GET_SCHEME_FALIURE";
export const GET_SCHEMES_ORGID_REQUESTED = "GET_SCHEMES_ORGID_REQUESTED";
export const GET_SCHEMES_ORGID_SUCCESS = "GET_SCHEMES_ORGID_SUCCESS";
export const GET_SCHEMES_ORGID_FAILURE = "GET_SCHEMES_ORGID_FAILURE";
export const GET_SCHEMES_RATES_ORGID_REQUESTED =
  "GET_SCHEMES_RATES_ORGID_REQUESTED";
export const GET_SCHEMES_RATES_ORGID_SUCCESS =
  "GET_SCHEMES_RATES_ORGID_SUCCESS";
export const GET_SCHEMES_RATES_ORGID_FAILURE =
  "GET_SCHEMES_RATES_ORGID_FAILURE";
export const GET_SCHEMES_RATES_ID_REQUESTED = "GET_SCHEMES_RATES_ID_REQUESTED";
export const GET_SCHEMES_RATES_ID_SUCCESS = "GET_SCHEMES_RATES_ID_SUCCESS";
export const GET_SCHEMES_RATES_ID_FAILURE = "GET_SCHEMES_RATES_ID_FAILURE";
export const GET_SCHEMES_REQUESTED = "GET_SCHEMES_REQUESTED";
export const GET_SCHEMES_SUCCESS = "GET_SCHEMES_SUCCESS";
export const GET_SCHEME_FAILURE = "GET_SCHEME_FAILURE";
export const GET_ALL_SCHEMES_REQUESTED = "GET_ALL_SCHEMES_REQUESTED";
export const GET_ALL_SCHEMES_SUCCESS = "GET_ALL_SCHEMES_SUCCESS";
export const GET_ALL_SCHEMES_FALIURE = "GET_ALL_SCHEMES_FALIURE";

export const DELETE_SCHEME_REQUESTED = "DELETE_SCHEME_REQUESTED";
export const DELETE_SCHEME_SUCCESS = "DELETE_SCHEME_SUCCESS";
export const DELETE_SCHEME_FAILURE = "DELETE_SCHEME_FAILURE";

const UPDATE_PATH = "UPDATE_PATH";

//=========== CREATE_SCHEME ==============
export const createScheme = (payload) => ({
  type: CREATE_SCHEME_REQUESTED,
  payload,
});

export const createSchemeSuccess = (payload) => ({
  type: CREATE_SCHEME_SUCCESS,
  payload,
});

export const createSchemeFailure = (error) => ({
  type: CREATE_SCHEME_FALIURE,
  error,
});

//=========== CREATE_SCHEME_RATES ========
export const createSchemeRates = (payload) => ({
  type: CREATE_SCHEME_RATES_REQUESTED,
  payload,
});

export const createSchemeRatesSuccess = (payload) => ({
  type: CREATE_SCHEME_RATES_SUCCESS,
  payload,
});

export const createSchemeRatesFailure = (error) => ({
  type: CREATE_SCHEME_RATES_FALIURE,
  error,
});

//=========== UPDATE_SCHEME ==============
export const updateScheme = (payload) => ({
  type: UPDATE_SCHEME_REQUESTED,
  payload,
});

export const updateSchemeSuccess = (payload) => ({
  type: UPDATE_SCHEME_SUCCESS,
  payload,
});

export const updateSchemeFailure = (error) => ({
  type: UPDATE_SCHEME_FAILURE,
  error,
});

//=========== UPDATE_SCHEME ==============
export const updateReduxScheme = (payload) => ({
  type: UPDATE_REDUX_SCHEME_REQUESTED,
  payload,
});

//=========== GET_SCHEME =================
export const getScheme = (id) => ({
  type: GET_SCHEME_REQUESTED,
  id,
});

export const getSchemeSuccess = (payload) => ({
  type: GET_SCHEME_SUCCESS,
  payload,
});

export const getSchemeFailure = (error) => ({
  type: GET_SCHEME_FAILURE,
  error,
});

//============ GET_SCHEMES_ORGID =========
export const getSchemesByOrgID = (id) => ({
  type: GET_SCHEMES_ORGID_REQUESTED,
  id,
});

export const getSchemesByOrgIDSuccess = (payload) => ({
  type: GET_SCHEMES_ORGID_SUCCESS,
  payload,
});

export const getSchemesByOrgIDFailure = (error) => ({
  type: GET_SCHEMES_ORGID_FAILURE,
  error,
});

// ========= GET_SCHEMES_RATES_ORGID =========
export const getSchemeRatesByOrgId = (id) => ({
  type: GET_SCHEMES_RATES_ORGID_REQUESTED,
  id,
});

export const getSchemeRatesByOrgIdSuccess = (payload) => ({
  type: GET_SCHEMES_RATES_ORGID_SUCCESS,
  payload,
});

export const getSchemeRatesByOrgIdFailure = (error) => ({
  type: GET_SCHEMES_RATES_ORGID_FAILURE,
  error,
});

//============ GET_SCHEMES_RATES_ID ==========
export const getSchemeRatesById = (id) => ({
  type: GET_SCHEMES_RATES_ID_REQUESTED,
  id,
});

export const getSchemeRatesByIdSuccess = (payload) => ({
  type: GET_SCHEMES_RATES_ID_SUCCESS,
  payload,
});

export const getSchemeRatesByIdFailure = (error) => ({
  type: GET_SCHEMES_RATES_ID_FAILURE,
  error,
});

export const getAllSchemes = () => ({
  type: GET_ALL_SCHEMES_REQUESTED,
});

//=========== DELETE_SCHEME ==============
export const deleteScheme = (payload) => ({
  type: DELETE_SCHEME_REQUESTED,
  payload,
});

export const deleteSchemeSuccess = (payload) => ({
  type: DELETE_SCHEME_SUCCESS,
  payload,
});

export const deleteSchemeFailure = (error) => ({
  type: DELETE_SCHEME_FAILURE,
  error,
});

export const updateSchemeLastPathRedux = (payload) => ({
  type: UPDATE_PATH,
  payload,
});

const initialState = {
  scheme: undefined,
  schemes: undefined,
  schemeRates: undefined,
  path: undefined,
  loading: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SCHEME_REQUESTED:
      return { ...state, loading: true };
    case CREATE_SCHEME_SUCCESS:
      return { ...state, scheme: action.payload, loading: false };
    case CREATE_SCHEME_FALIURE:
      return { ...state, error: action.error, loading: false };
    case CREATE_SCHEME_RATES_REQUESTED:
      return { ...state, loading: true };
    case CREATE_SCHEME_RATES_SUCCESS:
      return { ...state, schemeRates: action.payload, loading: false };
    case CREATE_SCHEME_RATES_FALIURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_SCHEME_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_SCHEME_SUCCESS:
      return { ...state, scheme: action.payload, loading: false };
    case UPDATE_SCHEME_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_REDUX_SCHEME_REQUESTED:
      return { ...state, scheme: action.payload };
    case GET_SCHEME_REQUESTED:
      return { ...state, loading: true };
    case GET_SCHEME_SUCCESS:
      return { ...state, scheme: action.payload, loading: false };
    case GET_SCHEME_FALIURE:
      return { ...state, error: action.error, loading: false };
    case GET_SCHEMES_ORGID_REQUESTED:
      return { ...state, loading: true };
    case GET_SCHEMES_ORGID_SUCCESS:
      return { ...state, schemes: action.payload, loading: false };
    case GET_SCHEMES_ORGID_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_ALL_SCHEMES_REQUESTED:
      return { ...state, loading: true };
    case GET_ALL_SCHEMES_SUCCESS:
      return { ...state, schemes: action.payload, loading: false };
    case GET_ALL_SCHEMES_FALIURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_SCHEME_REQUESTED:
      return { ...state, loading: true };
    case DELETE_SCHEME_SUCCESS:
      return {
        ...state,
        schemes: state.schemes.filter((item) => item.id !== action.payload),
        loading: false,
      };
    case DELETE_SCHEME_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_PATH:
      return { ...state, path: action.payload };
    default:
      return state;
  }
};
