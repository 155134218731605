import React from "react";
import { Container, Row, Spinner } from "reactstrap";

const Loader = (props) => (
  <Container
    fluid
    className={
      props.height === undefined ? "vh-75 d-flex" : `${props.height} d-flex`
    }
  >
    <Row className="justify-content-center align-self-center w-100 text-center">
      <Spinner color="primary" />
    </Row>
  </Container>
);

export default Loader;
