import { put, call } from "redux-saga/effects";
import {
  requestCreateUser,
  requestGetUsers,
  requestGetUserByEmail,
  requestUpdateUser,
  requestDeleteUser,
} from "../requests/user";
import {
  createUserFailure,
  createUserSuccess,
  getUsersSuccess,
  getUsersFailure,
  getUserSuccess,
  getUserFailure,
  updateUserSuccess,
  updateUserFailure,
  deleteUserSuccess,
  deleteUserFailure,
} from "../../ducks/user";

export function* handleCreateUser(action) {
  try {
    const response = yield call(requestCreateUser, action.payload);
    const { data } = response;
    yield put(createUserSuccess({ user: data.response }));
  } catch (err) {
    yield put(createUserFailure({ error: err.message }));
  }
}

export function* handleGetUsers(action) {
  try {
    const response = yield call(requestGetUsers, action.orgID);
    const { data } = response;
    yield put(getUsersSuccess({ users: data.response }));
  } catch (err) {
    yield put(getUsersFailure({ error: err }));
  }
}

export function* handleGetUser(action) {
  try {
    const response = yield call(requestGetUserByEmail, action.emailAddress);
    const { data } = response;
    yield put(getUserSuccess({ user: data.response }));
  } catch (err) {
    yield put(getUserFailure({ error: err }));
  }
}

export function* handleUpdateUser(action) {
  try {
    const response = yield call(requestUpdateUser, action.payload);
    const { data } = response;
    yield put(updateUserSuccess({ user: data.response }));
  } catch (err) {
    yield put(updateUserFailure({ error: err }));
  }
}

export function* handleDeleteUser(action) {
  try {
    yield call(requestDeleteUser, action.payload.id);
    yield put(deleteUserSuccess({ id: action.payload.id }));
  } catch (err) {
    yield put(deleteUserFailure({ error: err }));
  }
}
