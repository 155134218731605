export const UPLOAD_ORG_DOC_REQUESTED = "UPLOAD_ORG_DOC_REQUESTED";
export const UPLOAD_ORG_DOC_SUCCESS = "UPLOAD_ORG_DOC_SUCCESS";
export const UPLOAD_ORG_DOC_FAILURE = "UPLOAD_ORG_DOC_FAILURE";
export const UPDATE_ORG_DOC_REQUESTED = "UPDATE_ORG_DOC_REQUESTED";
export const UPDATE_ORG_DOC_SUCCESS = "UPDATE_ORG_DOC_SUCCESS";
export const UPDATE_ORG_DOC_FAILURE = "UPDATE_ORG_DOC_FAILURE";
export const DELETE_ORG_DOC_REQUESTED = "DELETE_ORG_DOC_REQUESTED";
export const DELETE_ORG_DOC_SUCCESS = "DELETE_ORG_DOC_SUCCESS";
export const DELETE_ORG_DOC_FAILURE = "DELETE_ORG_DOC_FAILURE";
export const CREATE_ORG_FOLDER_REQUESTED = "CREATE_ORG_FOLDER_REQUESTED";
export const CREATE_ORG_FOLDER_SUCCESS = "CREATE_ORG_FOLDER_SUCCESS";
export const CREATE_ORG_FOLDER_FAILURE = "CREATE_ORG_FOLDER_FAILURE";
export const GET_ORG_FOLDERS_REQUESTED = "GET_ORG_FOLDERS_REQUESTED";
export const GET_ORG_FOLDERS_SUCCESS = "GET_ORG_FOLDERS_SUCCESS";
export const GET_ORG_FOLDERS_FAILURE = "GET_ORG_FOLDERS_FAILURE";
export const UPDATE_ORG_FOLDER_REQUESTED = "UPDATE_ORG_FOLDER_REQUESTED";
export const UPDATE_ORG_FOLDER_SUCCESS = "UPDATE_ORG_FOLDER_SUCCESS";
export const UPDATE_ORG_FOLDER_FAILURE = "UPDATE_ORG_FOLDER_FAILURE";
export const DELETE_ORG_FOLDER_REQUESTED = "DELETE_ORG_FOLDER_REQUESTED";
export const DELETE_ORG_FOLDER_SUCCESS = "DELETE_ORG_FOLDER_SUCCESS";
export const DELETE_ORG_FOLDER_FAILURE = "DELETE_ORG_FOLDER_FAILURE";
export const UPLOAD_MEMBERSHIP_FILE_REQUESTED =
  "UPLOAD_MEMBERSHIP_FILE_REQUESTED";
export const UPLOAD_MEMBERSHIP_FILE_SUCCESS = "UPLOAD_MEMBERSHIP_FILE_SUCCESS";
export const UPLOAD_MEMBERSHIP_FILE_FAILURE = "UPLOAD_MEMBERSHIP_FILE_FAILURE";
export const UPLOAD_REDUX_ORG_DOC_REQUESTED = "UPLOAD_REDUX_ORG_DOC_REQUESTED";

// {
//     "id": "fake_data",
//     "policyId": "fake_data",
//     "folders": [
//         {

//             "name": "fake_data",
//             "files": [
//                 {

//                     "name": "fake_data",
//                     "url": "fake_data"
//                 },
//                 {

//                     "name": "fake_data",
//                     "url": "fake_data"
//                 },
//                 {

//                     "name": "fake_data",
//                     "url": "fake_data"
//                 }
//             ]
//         }
//     ]
// }

// /document/claim
// /document/policy
// /document/organization

export const uploadOrgDocument = (payload) => ({
  type: UPLOAD_ORG_DOC_REQUESTED,
  payload,
});
export const uploadOrgDocumentSuccess = (payload) => ({
  type: UPLOAD_ORG_DOC_SUCCESS,
  payload,
});
export const uploadOrgDocumentFailure = (error) => ({
  type: UPLOAD_ORG_DOC_FAILURE,
  error,
});

export const updateOrgDocument = (payload) => ({
  type: UPDATE_ORG_DOC_REQUESTED,
  payload,
});
export const updateOrgDocumentSuccess = (payload) => ({
  type: UPDATE_ORG_DOC_SUCCESS,
  payload,
});
export const updateOrgDocumentFailure = (error) => ({
  type: UPDATE_ORG_DOC_FAILURE,
  error,
});

export const deleteOrgDocument = (payload) => ({
  type: DELETE_ORG_DOC_REQUESTED,
  payload,
});
export const deleteOrgDocumentSuccess = (payload) => ({
  type: DELETE_ORG_DOC_SUCCESS,
  payload,
});
export const deleteOrgDocumentFailure = (error) => ({
  type: DELETE_ORG_DOC_FAILURE,
  error,
});

export const createOrgFolder = (payload) => ({
  type: CREATE_ORG_FOLDER_REQUESTED,
  payload,
});
export const createOrgFolderSuccess = (payload) => ({
  type: CREATE_ORG_FOLDER_SUCCESS,
  payload,
});
export const createOrgFolderFailure = (error) => ({
  type: CREATE_ORG_FOLDER_FAILURE,
  error,
});

export const getOrgFolders = (payload) => ({
  type: GET_ORG_FOLDERS_REQUESTED,
  payload,
});
export const getOrgFoldersSuccess = (payload) => ({
  type: GET_ORG_FOLDERS_SUCCESS,
  payload,
});
export const getOrgFoldersFailure = (error) => ({
  type: GET_ORG_FOLDERS_FAILURE,
  error,
});

export const updateOrgFolder = (payload) => ({
  type: UPDATE_ORG_FOLDER_REQUESTED,
  payload,
});
export const updateOrgFolderSuccess = (payload) => ({
  type: UPDATE_ORG_FOLDER_SUCCESS,
  payload,
});
export const updateOrgFolderFailure = (error) => ({
  type: UPDATE_ORG_FOLDER_FAILURE,
  error,
});

export const deleteOrgFolder = (payload) => ({
  type: DELETE_ORG_FOLDER_REQUESTED,
  payload,
});
export const deleteOrgFolderSuccess = (payload) => ({
  type: DELETE_ORG_FOLDER_SUCCESS,
  payload,
});
export const deleteOrgFolderFailure = (error) => ({
  type: DELETE_ORG_FOLDER_FAILURE,
  error,
});

export const uploadMembershipFile = (payload) => ({
  type: UPLOAD_MEMBERSHIP_FILE_REQUESTED,
  payload,
});
export const uploadMembershipFileSuccess = (payload) => ({
  type: UPLOAD_MEMBERSHIP_FILE_SUCCESS,
  payload,
});
export const uploadMembershipFileFailure = (error) => ({
  type: UPLOAD_MEMBERSHIP_FILE_FAILURE,
  error,
});

export const uploadReduxOrgDocument = (payload) => ({
  type: UPLOAD_REDUX_ORG_DOC_REQUESTED,
  payload,
});

const initialState = {
  orgFolders: undefined,
  orgFolder: undefined,
  orgDocument: undefined,
  loading: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_ORG_DOC_REQUESTED:
      return { ...state, loading: true };
    case UPLOAD_ORG_DOC_SUCCESS:
      return { ...state, orgDocument: action.payload, loading: false };
    case UPLOAD_ORG_DOC_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_ORG_DOC_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_ORG_DOC_SUCCESS:
      return { ...state, orgDocument: action.payload, loading: false };
    case UPDATE_ORG_DOC_FAILURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_ORG_DOC_REQUESTED:
      return { ...state, loading: true };
    case DELETE_ORG_DOC_SUCCESS:
      return { ...state, orgDocument: undefined, loading: false };
    case DELETE_ORG_DOC_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CREATE_ORG_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case CREATE_ORG_FOLDER_SUCCESS:
      const newFolder = [...state.orgFolders, action.payload.orgFolders];
      return { ...state, orgFolders: newFolder, loading: false };
    case CREATE_ORG_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_ORG_FOLDERS_REQUESTED:
      return { ...state, loading: true };
    case GET_ORG_FOLDERS_SUCCESS:
      return {
        ...state,
        orgFolders: action.payload,
        loading: false,
      };
    case GET_ORG_FOLDERS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_ORG_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_ORG_FOLDER_SUCCESS:
      return { ...state, orgFolder: action.payload, loading: false };
    case UPDATE_ORG_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_ORG_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case DELETE_ORG_FOLDER_SUCCESS:
      return { ...state, orgFolder: undefined, loading: false };
    case DELETE_ORG_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPLOAD_MEMBERSHIP_FILE_REQUESTED:
      return { ...state, loading: true };
    case UPLOAD_MEMBERSHIP_FILE_SUCCESS:
      return { ...state, orgDocument: action.payload, loading: false };
    case UPLOAD_MEMBERSHIP_FILE_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPLOAD_REDUX_ORG_DOC_REQUESTED:
      state.loading = true;
      const folder = state.orgFolders.filter(
        (item) => item.id === action.payload.id
      );
      const otherFolder = state.orgFolders.filter(
        (item) => item.id !== action.payload.id
      );
      folder[0].files = [...folder[0].files, action.payload.file];
      return {
        ...state,
        orgFolders: [...otherFolder, ...folder],
        loading: false,
      };
    default:
      return state;
  }
};
