import React from "react";

import {
  Badge,
  Card,
  CardHeader,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Table,
} from "reactstrap";

import { MoreVertical, RefreshCw } from "react-feather";

const TableComponent = () => (
  <Card className="flex-fill w-100">
    <CardHeader>
      <div className="card-actions float-right">
        <span className="cursor-pointer mr-1">
          <RefreshCw />
        </span>{" "}
        <UncontrolledDropdown className="d-inline-block">
          <DropdownToggle tag="a">
            <MoreVertical />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>Update</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <CardTitle tag="h5" className="mb-0">
        7 Latest Claims
      </CardTitle>
    </CardHeader>
    <Table striped className="my-0">
      <thead>
        <tr>
          <th>Principal Member</th>
          <th className="d-none d-xl-table-cell">Initialised Date</th>
          <th className="d-none d-xl-table-cell">Finalised Date</th>
          <th>Claim Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Apollo Creed</td>
          <td className="d-none d-xl-table-cell">01/01/2020</td>
          <td className="d-none d-xl-table-cell">31/06/2020</td>
          <td>
            <Badge color="success">Done</Badge>
          </td>
        </tr>
        <tr>
          <td>Bertha Martin</td>
          <td className="d-none d-xl-table-cell">01/01/2020</td>
          <td className="d-none d-xl-table-cell">-</td>
          <td>
            <Badge color="warning">In progress</Badge>
          </td>
        </tr>
        <tr>
          <td>Stacie Hall</td>
          <td className="d-none d-xl-table-cell">01/01/2020</td>
          <td className="d-none d-xl-table-cell">31/06/2020</td>
          <td>
            <Badge color="success">Done</Badge>
          </td>
        </tr>
        <tr>
          <td>Carl Jenkins</td>
          <td className="d-none d-xl-table-cell">01/01/2018</td>
          <td className="d-none d-xl-table-cell">-</td>
          <td>
            <Badge color="warning">In progress</Badge>
          </td>
        </tr>
        <tr>
          <td>Project Phoenix</td>
          <td className="d-none d-xl-table-cell">01/01/2020</td>
          <td className="d-none d-xl-table-cell">31/06/2020</td>
          <td>
            <Badge color="success">Done</Badge>
          </td>
        </tr>
        <tr>
          <td>Romeo Ceasar</td>
          <td className="d-none d-xl-table-cell">01/01/2020</td>
          <td className="d-none d-xl-table-cell">31/06/2020</td>
          <td>
            <Badge color="success">Done</Badge>
          </td>
        </tr>
        <tr>
          <td>Ashley Briggs</td>
          <td className="d-none d-xl-table-cell">01/01/2018</td>
          <td className="d-none d-xl-table-cell">-</td>
          <td>
            <Badge color="warning">In progress</Badge>
          </td>
        </tr>
      </tbody>
    </Table>
  </Card>
);

export default TableComponent;
