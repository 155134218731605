import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import BarChart from "./BarChart";
import Calendar from "./Calendar";
import Statistics from "./Statistics";
import Table from "./Table";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import EmptyState from "../../../components/EmptyState";

const Default = () => {
  const data = useSelector((state) => state.user.profile);
  const dashboardData = undefined;

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Welcome, {data.fullName}</HeaderTitle>
        {/* <HeaderSubtitle>
        You have 24 new messages and 5 new notifications.
      </HeaderSubtitle> */}
      </Header>

      {/* <EmptyState
              heading={"No folders created yet"}
              subheading={
                "You have no folders in this organization. To create one, click on the 'Add Folder' button above."
              }
            /> */}

      {/* <Row>
        <Col xl="6" className="d-flex col-xxl-5">
          <Statistics />
        </Col>
        <Col xl="6" className="d-flex col-xxl-7">
          <BarChart data={dashboardData} />
        </Col>
      </Row> */}
      <Row>
        <Col xl="12" className="col-xxl-12 d-flex order-1 order-xxl-1">
          <Calendar />
        </Col>

        {/* <Col xl="6" className="col-xxl-6  d-flex order-2 order-xxl-3">
          <Table />
        </Col> */}
      </Row>
    </Container>
  );
};

export default Default;
