export const CREATE_UNDERWRITER_REQUESTED = "CREATE_UNDERWRITER_REQUESTED";
export const CREATE_UNDERWRITER_SUCCESS = "CREATE_UNDERWRITER_SUCCESS";
export const CREATE_UNDERWRITER_FAILURE = "CREATE_UNDERWRITER_FAILURE";
export const CREATE_UNDERWRITER_RATES_REQUESTED =
  "CREATE_UNDERWRITER_RATES_REQUESTED";
export const CREATE_UNDERWRITER_RATES_SUCCESS =
  "CREATE_UNDERWRITER_RATES_SUCCESS";
export const CREATE_UNDERWRITER_RATES_FAILURE =
  "CREATE_UNDERWRITER_RATES_FAILURE";
export const UPDATE_UNDERWRITER_REQUESTED = "UPDATE_UNDERWRITER_REQUESTED";
export const UPDATE_UNDERWRITER_SUCCESS = "UPDATE_UNDERWRITER_SUCCESS";
export const UPDATE_UNDERWRITER_FAILURE = "UPDATE_UNDERWRITER_FAILURE";
export const DELETE_UNDERWRITER_RATES_REQUESTED =
  "DELETE_UNDERWRITER_RATES_REQUESTED";
export const DELETE_UNDERWRITER_RATES_SUCCESS =
  "DELETE_UNDERWRITER_RATES_SUCCESS";
export const DELETE_UNDERWRITER_RATES_FAILURE =
  "DELETE_UNDERWRITER_RATES_FAILURE";
export const GET_UNDERWRITER_REQUESTED = "GET_UNDERWRITER_REQUESTED";
export const GET_UNDERWRITER_SUCCESS = "GET_UNDERWRITER_SUCCESS";
export const GET_UNDERWRITER_FAILURE = "GET_UNDERWRITER_FAILURE";
export const GET_UNDERWRITERS_ORGID_REQUESTED =
  "GET_UNDERWRITERS_ORGID_REQUESTED";
export const GET_UNDERWRITERS_ORGID_SUCCESS = "GET_UNDERWRITERS_ORGID_SUCCESS";
export const GET_UNDERWRITERS_ORGID_FAILURE = "GET_UNDERWRITERS_ORGID_FAILURE";
export const GET_UNDERWRITERS_RATES_ORGID_REQUESTED =
  "GET_UNDERWRITERS_RATES_ORGID_REQUESTED";
export const GET_UNDERWRITERS_RATES_ORGID_SUCCESS =
  "GET_UNDERWRITERS_RATES_ORGID_SUCCESS";
export const GET_UNDERWRITERS_RATES_ORGID_FAILURE =
  "GET_UNDERWRITERS_RATES_ORGID_FAILURE";
export const UPDATE_UNDERWRITER_RATES_REQUESTED =
  "UPDATE_UNDERWRITER_RATES_REQUESTED";
export const UPDATE_UNDERWRITER_RATES_SUCCESS =
  "UPDATE_UNDERWRITER_RATES_SUCCESS";
export const UPDATE_UNDERWRITER_RATES_FAILURE =
  "UPDATE_UNDERWRITER_RATES_FAILURE";
export const GET_UNDERWRITER_RATES_ID_REQUESTED =
  "GET_UNDERWRITER_RATES_ID_REQUESTED";
export const GET_UNDERWRITER_RATES_ID_SUCCESS =
  "GET_UNDERWRITER_RATES_ID_SUCCESS";
export const GET_UNDERWRITER_RATES_ID_FAILURE =
  "GET_UNDERWRITER_RATES_ID_FAILURE";
export const CLONE_UNDERWRITER_RATES_ID_REQUESTED =
  "CLONE_UNDERWRITER_RATES_ID_REQUESTED";
export const CLONE_UNDERWRITER_RATES_ID_SUCCESS =
  "CLONE_UNDERWRITER_RATES_ID_SUCCESS";
export const CLONE_UNDERWRITER_RATES_ID_FAILURE =
  "CLONE_UNDERWRITER_RATES_ID_FAILURE";
export const GET_UNDERWRITER_RATES_UNDERWRITERID_REQUESTED =
  "GET_UNDERWRITER_RATES_UNDERWRITERID_REQUESTED";
export const GET_UNDERWRITER_RATES_UNDERWRITERID_SUCCESS =
  "GET_UNDERWRITER_RATES_UNDERWRITERID_SUCCESS";
export const GET_UNDERWRITER_RATES_UNDERWRITERID_FAILURE =
  "GET_UNDERWRITER_RATES_UNDERWRITERID_FAILURE";
export const GET_UNDERWRITERS_REQUESTED = "GET_UNDERWRITERS_REQUESTED";
export const GET_UNDERWRITERS_SUCCESS = "GET_UNDERWRITERS_SUCCESS";
export const GET_UNDERWRITERS_FAILURE = "GET_UNDERWRITERS_FAILURE";
export const GET_ALL_UNDERWRITERS_REQUESTED = "GET_ALL_UNDERWRITERS_REQUESTED";
export const GET_ALL_UNDERWRITERS_SUCCESS = "GET_ALL_UNDERWRITERS_SUCCESS";
export const GET_ALL_UNDERWRITERS_FAILURE = "GET_ALL_UNDERWRITERS_FAILURE";
export const DELETE_UNDERWRITER_REQUESTED = "DELETE_UNDERWRITER_REQUESTED";
export const DELETE_UNDERWRITER_SUCCESS = "DELETE_UNDERWRITER_SUCCESS";
export const DELETE_UNDERWRITER_FAILURE = "DELETE_UNDERWRITER_FAILURE";

//=========== CREATE_UNDERWRITER ==============
export const createUnderwriter = (payload) => ({
  type: CREATE_UNDERWRITER_REQUESTED,
  payload,
});

export const createUnderwriterSuccess = (payload) => ({
  type: CREATE_UNDERWRITER_SUCCESS,
  payload,
});

export const createUnderwriterFailure = (error) => ({
  type: CREATE_UNDERWRITER_FAILURE,
  error,
});

//=========== CREATE_UNDERWRITER_RATES ========
export const createUnderwriterRates = (payload) => ({
  type: CREATE_UNDERWRITER_RATES_REQUESTED,
  payload,
});

export const createUnderwriterRatesSuccess = (payload) => ({
  type: CREATE_UNDERWRITER_RATES_SUCCESS,
  payload,
});

export const createUnderwriterRatesFailure = (error) => ({
  type: CREATE_UNDERWRITER_RATES_FAILURE,
  error,
});

//=========== UPDATE_UNDERWRITER ==============
export const updateUnderwriter = (payload) => ({
  type: UPDATE_UNDERWRITER_REQUESTED,
  payload,
});

export const updateUnderwriterSuccess = (payload) => ({
  type: UPDATE_UNDERWRITER_SUCCESS,
  payload,
});

export const updateUnderwriterFailure = (err) => ({
  type: UPDATE_UNDERWRITER_FAILURE,
  err,
});

//=========== GET_UNDERWRITER =================
export const getUnderwriter = (id) => ({
  type: GET_UNDERWRITER_REQUESTED,
  id,
});

export const getUnderwriterSuccess = (payload) => ({
  type: GET_UNDERWRITER_SUCCESS,
  payload,
});

export const getUnderwriterFailure = (error) => ({
  type: GET_UNDERWRITER_FAILURE,
  error,
});

//============ GET_UNDERWRITERS_ORGID =========
export const getUnderwritersByOrgID = (id) => ({
  type: GET_UNDERWRITERS_ORGID_REQUESTED,
  id,
});

export const getUnderwritersByOrgIDSuccess = (payload) => ({
  type: GET_UNDERWRITERS_ORGID_SUCCESS,
  payload,
});

export const getUnderwritersByOrgIDFailure = (error) => ({
  type: GET_UNDERWRITERS_ORGID_FAILURE,
  error,
});

// ========= GET_UNDERWRITERS_RATES_ORGID =========
export const getUnderwriterRatesByOrgId = (id) => ({
  type: GET_UNDERWRITERS_RATES_ORGID_REQUESTED,
  id,
});

export const getUnderwriterRatesByOrgIdSuccess = (payload) => ({
  type: GET_UNDERWRITERS_RATES_ORGID_SUCCESS,
  payload,
});

export const getUnderwriterRatesByOrgIdFailure = (error) => ({
  type: GET_UNDERWRITERS_RATES_ORGID_FAILURE,
  error,
});

export const getUnderwriterRatesByUnderwriterId = (id) => ({
  type: GET_UNDERWRITER_RATES_UNDERWRITERID_REQUESTED,
  id,
});

export const getUnderwriterRatesByUnderwriterIdSuccess = (payload) => ({
  type: GET_UNDERWRITER_RATES_UNDERWRITERID_SUCCESS,
  payload,
});

export const getUnderwriterRatesByUnderwriterIdFailure = (error) => ({
  type: GET_UNDERWRITER_RATES_UNDERWRITERID_FAILURE,
  error,
});

// ========= UPDATE_UNDERWRITERS_RATES_ORGID =========
export const updateUnderwriterRates = (payload) => ({
  type: UPDATE_UNDERWRITER_RATES_REQUESTED,
  payload,
});

export const updateUnderwriterRatesSuccess = (payload) => ({
  type: UPDATE_UNDERWRITER_RATES_SUCCESS,
  payload,
});

export const updateUnderwriterRatesFailure = (error) => ({
  type: UPDATE_UNDERWRITER_RATES_FAILURE,
  error,
});

//============ GET_UNDERWRITERS_RATES_ID ==========
export const getUnderwriterRatesById = (id) => ({
  type: GET_UNDERWRITER_RATES_ID_REQUESTED,
  id,
});

export const getUnderwriterRatesByIdSuccess = (payload) => ({
  type: GET_UNDERWRITER_RATES_ID_SUCCESS,
  payload,
});

export const getUnderwriterRatesByIdFailure = (error) => ({
  type: GET_UNDERWRITER_RATES_ID_FAILURE,
  error,
});

export const getAllUnderwriters = () => ({
  type: GET_ALL_UNDERWRITERS_REQUESTED,
});

//=========== CLONE_UNDERWRITER =================
export const cloneUnderwriterRatesById = (id) => ({
  type: CLONE_UNDERWRITER_RATES_ID_REQUESTED,
  id,
});

export const cloneUnderwriterRatesByIdSuccess = (payload) => ({
  type: CLONE_UNDERWRITER_RATES_ID_SUCCESS,
  payload,
});

export const cloneUnderwriterRatesByIdFailure = (error) => ({
  type: CLONE_UNDERWRITER_RATES_ID_FAILURE,
  error,
});

//=========== DELETE_UNDERWRITER_RATES ========
export const deleteUnderwriterRates = (payload) => ({
  type: DELETE_UNDERWRITER_RATES_REQUESTED,
  payload,
});

export const deleteUnderwriterRatesSuccess = (payload) => ({
  type: DELETE_UNDERWRITER_RATES_SUCCESS,
  payload,
});

export const deleteUnderwriterRatesFailure = (error) => ({
  type: DELETE_UNDERWRITER_RATES_FAILURE,
  error,
});

const initialState = {
  underwriter: undefined,
  underwriters: undefined,
  underwriterRates: undefined,
  underwriterRate: undefined,
  loading: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_UNDERWRITER_REQUESTED:
      return { ...state, loading: true };
    case CREATE_UNDERWRITER_SUCCESS:
      return { ...state, underwriter: action.payload, loading: false };
    case CREATE_UNDERWRITER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CREATE_UNDERWRITER_RATES_REQUESTED:
      return { ...state, loading: true };
    case CREATE_UNDERWRITER_RATES_SUCCESS:
      return { ...state, underwriterRates: action.payload, loading: false };
    case CREATE_UNDERWRITER_RATES_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_UNDERWRITER_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_UNDERWRITER_SUCCESS:
      return { ...state, underwriter: action.payload, loading: false };
    case UPDATE_UNDERWRITER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_UNDERWRITER_REQUESTED:
      return { ...state, loading: true };
    case GET_UNDERWRITER_SUCCESS:
      return { ...state, underwriter: action.payload, loading: false };
    case GET_UNDERWRITER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_UNDERWRITERS_ORGID_REQUESTED:
      return { ...state, loading: true };
    case GET_UNDERWRITERS_ORGID_SUCCESS:
      return { ...state, underwriters: action.payload, loading: false };
    case GET_UNDERWRITERS_ORGID_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_ALL_UNDERWRITERS_REQUESTED:
      return { ...state, loading: true };
    case GET_ALL_UNDERWRITERS_SUCCESS:
      return { ...state, underwriters: action.payload, loading: false };
    case GET_ALL_UNDERWRITERS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_UNDERWRITERS_RATES_ORGID_REQUESTED:
      return { ...state, loading: true };
    case GET_UNDERWRITERS_RATES_ORGID_SUCCESS:
      return { ...state, underwriterRates: action.payload, loading: false };
    case GET_UNDERWRITERS_RATES_ORGID_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_UNDERWRITER_RATES_UNDERWRITERID_REQUESTED:
      return { ...state, loading: true };
    case GET_UNDERWRITER_RATES_UNDERWRITERID_SUCCESS: {
      const updated = {
        ...state,
        underwriterRates: action.payload,
        loading: false,
      };
      return updated;
    }
    case GET_UNDERWRITER_RATES_UNDERWRITERID_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CLONE_UNDERWRITER_RATES_ID_REQUESTED:
      return { ...state, loading: true };
    case CLONE_UNDERWRITER_RATES_ID_SUCCESS: {
      const updated = {
        ...state,
        underwriterRates: [...state.underwriterRates, action.payload],
        loading: false,
      };
      return updated;
    }
    case CLONE_UNDERWRITER_RATES_ID_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_UNDERWRITER_RATES_ID_REQUESTED:
      return { ...state, loading: true };
    case GET_UNDERWRITER_RATES_ID_SUCCESS:
      return { ...state, underwriterRate: action.payload, loading: false };
    case GET_UNDERWRITER_RATES_ID_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_UNDERWRITER_RATES_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_UNDERWRITER_RATES_SUCCESS:
      return { ...state, underwriterRate: action.payload, loading: false };
    case UPDATE_UNDERWRITER_RATES_FAILURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_UNDERWRITER_RATES_REQUESTED:
      return { ...state, loading: true };
    case DELETE_UNDERWRITER_RATES_SUCCESS:
      const updatedUnderwriterRate = state.underwriterRate.filter(
        (underwriter) => underwriter.id !== action.payload.id
      );
      return {
        ...state,
        underwriterRate: updatedUnderwriterRate,
        loading: false,
      };
    case DELETE_UNDERWRITER_RATES_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};
