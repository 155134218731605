export const CREATE_USER_REQUESTED = "CREATE_USER_REQUESTED";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";
export const UPDATE_USER_REQUESTED = "UPDATE_USER_REQUESTED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_REQUESTED = "DELETE_USER_REQUESTED";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const GET_USER_REQUESTED = "GET_USER_REQUESTED";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const GET_USERS_REQUESTED = "GET_USERS_REQUESTED";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const GET_ALL_USERS_REQUESTED = "GET_ALL_USERS_REQUESTED";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

export const SET_PROFILE_REQUESTED = "SET_PROFILE_REQUESTED";
export const GET_PROFILE_REQUESTED = "GET_PROFILE_REQUESTED";

export const createUser = (payload) => ({
  type: CREATE_USER_REQUESTED,
  payload,
});
export const createUserSuccess = (payload) => ({
  type: CREATE_USER_SUCCESS,
  payload,
});
export const createUserFailure = (error) => ({
  type: CREATE_USER_FAILURE,
  error,
});

export const updateUser = (payload, id) => ({
  type: UPDATE_USER_REQUESTED,
  payload,
  id,
});

export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  error,
});

export const deleteUser = (payload) => ({
  type: DELETE_USER_REQUESTED,
  payload,
});

export const deleteUserSuccess = (payload) => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserFailure = (error) => ({
  type: DELETE_USER_FAILURE,
  error,
});

export const getUser = (emailAddress) => ({
  type: GET_USER_REQUESTED,
  emailAddress,
});

export const getUserSuccess = (payload) => ({
  type: GET_USER_SUCCESS,
  payload,
});

export const getUserFailure = (error) => ({
  type: GET_USER_FAILURE,
  error,
});

export const getUsers = (orgID) => ({
  type: GET_USERS_REQUESTED,
  orgID,
});

export const getUsersSuccess = (payload) => ({
  type: GET_USERS_SUCCESS,
  payload,
});

export const getUsersFailure = (error) => ({
  type: GET_USERS_FAILURE,
  error,
});

export const getAllUsers = () => ({
  type: GET_ALL_USERS_REQUESTED,
});

export const getAllUsersSuccess = (payload) => ({
  type: GET_ALL_USERS_REQUESTED,
  payload,
});

export const getAllUsersFailure = (error) => ({
  type: GET_ALL_USERS_FAILURE,
  error,
});

export const setProfile = (payload) => ({
  type: SET_PROFILE_REQUESTED,
  payload,
});

export const getProfile = () => ({
  type: GET_PROFILE_REQUESTED,
});

const initialState = {
  profile: undefined,
  user: undefined,
  users: undefined,
  loading: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUESTED:
      return { ...state, loading: true };
    case CREATE_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false };
    case CREATE_USER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_USER_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false };
    case UPDATE_USER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_USER_REQUESTED:
      return { ...state, loading: true };
    case GET_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false };
    case GET_USER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_USERS_REQUESTED:
      return { ...state, loading: true };
    case GET_USERS_SUCCESS:
      return { ...state, users: action.payload.users, loading: false };
    case GET_USERS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_ALL_USERS_REQUESTED:
      return { ...state, loading: true };
    case GET_ALL_USERS_SUCCESS:
      return { ...state, users: action.payload, loading: false };
    case GET_ALL_USERS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_USER_REQUESTED:
      return { ...state, loading: true };
    case DELETE_USER_SUCCESS:
      const payload = state.users.filter(
        (user) => user.id !== action.payload.id
      );
      return { ...state, users: payload, loading: false };
    case DELETE_USER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case SET_PROFILE_REQUESTED:
      return { ...state, profile: action.payload };
    case GET_PROFILE_REQUESTED:
      return state.profile;
    default:
      return state;
  }
};
