import axios from "axios";

export function requestCreatePremium(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/premium`,
    data,
  });
}

export function requestUpdatePremium(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/premium`,
    data,
  });
}

export function requestUpdateSinglePremium(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/premium/single`,
    data,
  });
}

export function requestGetPremiumByMemberId(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/premium/member/${data}`,
  });
}

export function requestGetPremiumByAccountNumber(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/premium/account/${data}`,
  });
}

export function requestGetPremiumByPaymentRef(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/premium/payment/${data}`,
  });
}

export function requestGetPremiumByPolicyNumber(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/premium/policy/${data}`,
  });
}

export function requestGetSinglePremiumByPolicyNumber(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/premium/single/policy/${data}`,
  });
}
