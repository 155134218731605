import axios from "axios";

export function requestGetSafricanPremiumBySchemeNo(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/report/premiums/safrican/${data.scheme}/${data.month}/${data.year}`,
  });
}

export function requestGetKingPricePremiumBySchemeNo(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/report/premiums/kingprice/${data.scheme}/${data.month}/${data.year}`,
  });
}

export function requestGetKingPriceNewBusinessBySchemeNo(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/report/newbusiness/kingprice/${data.scheme}/${data.month}/${data.year}`,
  });
}

export function requestGetKingPriceAmendmentsBySchemeNo(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/report/amendments/kingprice/${data.scheme}/${data.month}/${data.year}`,
  });
}
