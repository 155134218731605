import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers/index";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { watchSaga } from "../saga/rootSaga";

const persistConfig = {
  key: "root",
  storage,
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

/* eslint-disable no-underscore-dangle */
const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(...middleware))
);
/* eslint-enable */

sagaMiddleware.run(watchSaga);

const persistor = persistStore(store);

export { store, persistor };
