import { put, call } from "redux-saga/effects";
import {
  requestCreateScheme,
  requestGetScheme,
  requestGetSchemesByOrgId,
  requestUpdateScheme,
  requestDeleteScheme,
} from "../requests/scheme";
import {
  createSchemeFailure,
  createSchemeSuccess,
  getSchemesByOrgIDSuccess,
  getSchemesByOrgIDFailure,
  getSchemeSuccess,
  getSchemeFailure,
  updateSchemeSuccess,
  updateSchemeFailure,
  deleteSchemeSuccess,
  deleteSchemeFailure,
} from "../../ducks/scheme";

export function* handleCreateScheme(action) {
  try {
    const response = yield call(requestCreateScheme, action.payload);
    const { data } = response;
    yield put(createSchemeSuccess({ scheme: data.response.scheme }));
  } catch (err) {
    yield put(createSchemeFailure({ error: err.message }));
  }
}

export function* handleGetScheme(action) {
  try {
    const response = yield call(requestGetScheme, action.id);
    yield put(getSchemeSuccess({ scheme: response.data.response }));
  } catch (err) {
    yield put(getSchemeFailure({ error: err.message }));
  }
}

export function* handleGetSchemesByOrgId(action) {
  try {
    const response = yield call(requestGetSchemesByOrgId, action.id);
    const { data } = response;

    yield put(getSchemesByOrgIDSuccess(data.response));
  } catch (err) {
    //{"message":"Something went wrong while trying to get schemes","code":null,"response":"a9e44b17-d40a-43c3-a0ad-8d4f315bb47a"}

    yield put(getSchemesByOrgIDFailure({ error: err.message }));
  }
}

export function* handleUpdateScheme(action) {
  try {
    const response = yield call(requestUpdateScheme, action.payload);
    const { data } = response;
    yield put(updateSchemeSuccess({ scheme: data.response }));
  } catch (err) {
    yield put(updateSchemeFailure({ error: err.message }));
  }
}

export function* handleDeleteScheme(action) {
  try {
    const response = yield call(requestDeleteScheme, action.payload);
    const { data } = response;
    yield put(deleteSchemeSuccess(data.response));
  } catch (err) {
    yield put(deleteSchemeFailure({ error: err.message }));
  }
}
