export const CREATE_POLICY_DOC_REQUESTED = "CREATE_POLICY_DOC_REQUESTED";
export const CREATE_POLICY_DOC_SUCCESS = "CREATE_POLICY_DOC_SUCCESS";
export const CREATE_POLICY_DOC_FAILURE = "CREATE_POLICY_DOC_FAILURE";
export const UPDATE_POLICY_DOC_REQUESTED = "UPDATE_POLICY_DOC_REQUESTED";
export const UPDATE_POLICY_DOC_SUCCESS = "UPDATE_POLICY_DOC_SUCCESS";
export const UPDATE_POLICY_DOC_FAILURE = "UPDATE_POLICY_DOC_FAILURE";
export const DELETE_POLICY_DOC_REQUESTED = "DELETE_POLICY_DOC_REQUESTED";
export const DELETE_POLICY_DOC_SUCCESS = "DELETE_POLICY_DOC_SUCCESS";
export const DELETE_POLICY_DOC_FAILURE = "DELETE_POLICY_DOC_FAILURE";
export const CREATE_POLICY_FOLDER_REQUESTED = "CREATE_POLICY_FOLDER_REQUESTED";
export const CREATE_POLICY_FOLDER_SUCCESS = "CREATE_POLICY_FOLDER_SUCCESS";
export const CREATE_POLICY_FOLDER_FAILURE = "CREATE_POLICY_FOLDER_FAILURE";
export const GET_POLICY_FOLDERS_REQUESTED = "GET_POLICY_FOLDERS_REQUESTED";
export const GET_POLICY_FOLDERS_SUCCESS = "GET_POLICY_FOLDERS_SUCCESS";
export const GET_POLICY_FOLDERS_FAILURE = "GET_POLICY_FOLDERS_FAILURE";
export const UPDATE_POLICY_FOLDER_REQUESTED = "UPDATE_POLICY_FOLDER_REQUESTED";
export const UPDATE_POLICY_FOLDER_SUCCESS = "UPDATE_POLICY_FOLDER_SUCCESS";
export const UPDATE_POLICY_FOLDER_FAILURE = "UPDATE_POLICY_FOLDER_FAILURE";
export const ACTIVE_POLICY_FOLDER_REQUESTED = "ACTIVE_POLICY_FOLDER_REQUESTED";
export const ACTIVE_POLICY_FOLDER_SUCCESS = "ACTIVE_POLICY_FOLDER_SUCCESS";
export const ACTIVE_POLICY_FOLDER_FAILURE = "ACTIVE_POLICY_FOLDER_FAILURE";
export const DELETE_POLICY_FOLDER_REQUESTED = "DELETE_POLICY_FOLDER_REQUESTED";
export const DELETE_POLICY_FOLDER_SUCCESS = "DELETE_POLICY_FOLDER_SUCCESS";
export const DELETE_POLICY_FOLDER_FAILURE = "DELETE_POLICY_FOLDER_FAILURE";
export const UPLOAD_REDUX_POLICY_DOC_REQUESTED =
  "UPLOAD_REDUX_POLICY_DOC_REQUESTED";

// {
//     "id": "fake_data",
//     "policyId": "fake_data",
//     "folders": [
//         {

//             "name": "fake_data",
//             "files": [
//                 {

//                     "name": "fake_data",
//                     "url": "fake_data"
//                 },
//                 {

//                     "name": "fake_data",
//                     "url": "fake_data"
//                 },
//                 {

//                     "name": "fake_data",
//                     "url": "fake_data"
//                 }
//             ]
//         }
//     ]
// }

export const createPolicyDocument = (payload) => ({
  type: CREATE_POLICY_DOC_REQUESTED,
  payload,
});
export const createPolicyDocumentSuccess = (payload) => ({
  type: CREATE_POLICY_DOC_SUCCESS,
  payload,
});
export const createPolicyDocumentFailure = (error) => ({
  type: CREATE_POLICY_DOC_FAILURE,
  error,
});

export const updatePolicyDocument = (payload) => ({
  type: UPDATE_POLICY_DOC_REQUESTED,
  payload,
});
export const updatePolicyDocumentSuccess = (payload) => ({
  type: UPDATE_POLICY_DOC_SUCCESS,
  payload,
});
export const updatePolicyDocumentFailure = (error) => ({
  type: UPDATE_POLICY_DOC_FAILURE,
  error,
});

export const deletePolicyDocument = (payload) => ({
  type: DELETE_POLICY_DOC_REQUESTED,
  payload,
});
export const deletePolicyDocumentSuccess = (payload) => ({
  type: DELETE_POLICY_DOC_SUCCESS,
  payload,
});
export const deletePolicyDocumentFailure = (error) => ({
  type: DELETE_POLICY_DOC_FAILURE,
  error,
});

export const createPolicyFolder = (payload) => ({
  type: CREATE_POLICY_FOLDER_REQUESTED,
  payload,
});
export const createPolicyFolderSuccess = (payload) => ({
  type: CREATE_POLICY_FOLDER_SUCCESS,
  payload,
});
export const createPolicyFolderFailure = (error) => ({
  type: CREATE_POLICY_FOLDER_FAILURE,
  error,
});

export const getPolicyFolders = (payload) => ({
  type: GET_POLICY_FOLDERS_REQUESTED,
  payload,
});
export const getPolicyFoldersSuccess = (payload) => ({
  type: GET_POLICY_FOLDERS_SUCCESS,
  payload,
});
export const getPolicyFoldersFailure = (error) => ({
  type: GET_POLICY_FOLDERS_FAILURE,
  error,
});

export const updatePolicyFolder = (payload) => ({
  type: UPDATE_POLICY_FOLDER_REQUESTED,
  payload,
});
export const updatePolicyFolderSuccess = (payload) => ({
  type: UPDATE_POLICY_FOLDER_SUCCESS,
  payload,
});
export const updatePolicyFolderFailure = (error) => ({
  type: UPDATE_POLICY_FOLDER_FAILURE,
  error,
});

export const activePolicyFolder = (payload) => ({
  type: ACTIVE_POLICY_FOLDER_REQUESTED,
  payload,
});
export const activePolicyFolderSuccess = (payload) => ({
  type: ACTIVE_POLICY_FOLDER_SUCCESS,
  payload,
});
export const activePolicyFolderFailure = (error) => ({
  type: ACTIVE_POLICY_FOLDER_FAILURE,
  error,
});

export const deletePolicyFolder = (payload) => ({
  type: DELETE_POLICY_FOLDER_REQUESTED,
  payload,
});
export const deletePolicyFolderSuccess = (payload) => ({
  type: DELETE_POLICY_FOLDER_SUCCESS,
  payload,
});
export const deletePolicyFolderFailure = (error) => ({
  type: DELETE_POLICY_FOLDER_FAILURE,
  error,
});

export const uploadReduxPolicyDocument = (payload) => ({
  type: UPLOAD_REDUX_POLICY_DOC_REQUESTED,
  payload,
});

const initialState = {
  policyFolders: undefined,
  policyFolder: undefined,
  policyDocument: undefined,
  loading: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POLICY_DOC_REQUESTED:
      return { ...state, loading: true };
    case CREATE_POLICY_DOC_SUCCESS:
      return { ...state, policyDocument: action.payload, loading: false };
    case CREATE_POLICY_DOC_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_POLICY_DOC_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_POLICY_DOC_SUCCESS:
      return { ...state, policyDocument: action.payload, loading: false };
    case UPDATE_POLICY_DOC_FAILURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_POLICY_DOC_REQUESTED:
      return { ...state, loading: true };
    case DELETE_POLICY_DOC_SUCCESS:
      return { ...state, policyDocument: undefined, loading: false };
    case DELETE_POLICY_DOC_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CREATE_POLICY_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case CREATE_POLICY_FOLDER_SUCCESS:
      const newFolder = [...state.policyFolders, action.payload.policyFolders];
      return { ...state, policyFolders: newFolder, loading: false };
    case CREATE_POLICY_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_POLICY_FOLDERS_REQUESTED:
      return { ...state, loading: true };
    case GET_POLICY_FOLDERS_SUCCESS:
      return {
        ...state,
        policyFolders: action.payload.policy,
        loading: false,
      };
    case GET_POLICY_FOLDERS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_POLICY_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_POLICY_FOLDER_SUCCESS:
      return { ...state, policyFolder: action.payload, loading: false };
    case UPDATE_POLICY_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case ACTIVE_POLICY_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case ACTIVE_POLICY_FOLDER_SUCCESS:
      return { ...state, policyFolder: action.payload, loading: false };
    case ACTIVE_POLICY_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_POLICY_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case DELETE_POLICY_FOLDER_SUCCESS:
      return { ...state, policyFolder: undefined, loading: false };
    case DELETE_POLICY_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPLOAD_REDUX_POLICY_DOC_REQUESTED:
      state.loading = true;
      const folder = state.policyFolders.filter(
        (item) => item.id === action.payload.id
      );
      const otherFolder = state.policyFolders.filter(
        (item) => item.id !== action.payload.id
      );
      folder[0].files = [...folder[0].files, action.payload.file];
      return {
        ...state,
        policyFolders: [...otherFolder, ...folder],
        loading: false,
      };
    default:
      return state;
  }
};
