import { put, call } from "redux-saga/effects";
import { getAuditLogsSuccess, getFailure } from "../../ducks/audit";
import { requestGetAuditLogs } from "../requests/audit";

export function* handleGetAuditLogs(action) {
  try {
    const response = yield call(requestGetAuditLogs, action);
    yield put(getAuditLogsSuccess({ report: response.data.response }));
  } catch (err) {
    yield put(getFailure({ error: err.message }));
  }
}
