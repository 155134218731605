export const GET_SAFRICAN_PREMIUMS_REQUESTED =
  "GET_SAFRICAN_PREMIUMS_REQUESTED";
export const GET_SAFRICAN_PREMIUMS_SUCCESS = "GET_SAFRICAN_PREMIUMS_SUCCESS";
export const GET_SAFRICAN_PREMIUMS_FAILURE = "GET_SAFRICAN_PREMIUMS_FAILURE";
export const GET_KING_PRICE_PREMIUMS_REQUESTED =
  "GET_KING_PRICE_PREMIUMS_REQUESTED";
export const GET_KING_PRICE_PREMIUMS_SUCCESS =
  "GET_KING_PRICE_PREMIUMS_SUCCESS";
export const GET_KING_PRICE_PREMIUMS_FAILURE =
  "GET_KING_PRICE_PREMIUMS_FAILURE";
export const GET_KING_PRICE_NEW_BUSINESS_REQUESTED =
  "GET_KING_PRICE_NEW_BUSINESS_REQUESTED";
export const GET_KING_PRICE_NEW_BUSINESS_SUCCESS =
  "GET_KING_PRICE_NEW_BUSINESS_SUCCESS";
export const GET_KING_PRICE_NEW_BUSINESS_FAILURE =
  "GET_KING_PRICE_NEW_BUSINESS_FAILURE";
export const GET_KING_PRICE_AMENDMENTS_REQUESTED =
  "GET_KING_PRICE_AMENDMENTS_REQUESTED";
export const GET_KING_PRICE_AMENDMENTS_SUCCESS =
  "GET_KING_PRICE_AMENDMENTS_SUCCESS";
export const GET_KING_PRICE_AMENDMENTS_FAILURE =
  "GET_KING_PRICE_AMENDMENTS_FAILURE";

const CLEAR_SAFRICAN_PREMIUMS = "CLEAR_SAFRICAN_PREMIUMS";

export const getSafricanPremiums = (scheme) => ({
  type: GET_SAFRICAN_PREMIUMS_REQUESTED,
  scheme,
});

export const getSafricanPremiumsSuccess = (payload) => ({
  type: GET_SAFRICAN_PREMIUMS_SUCCESS,
  payload,
});

export const getSafricanPremiumsFailure = (error) => ({
  type: GET_SAFRICAN_PREMIUMS_FAILURE,
  error,
});

export const getKingPricePremiums = (scheme) => ({
  type: GET_KING_PRICE_PREMIUMS_REQUESTED,
  scheme,
});

export const getKingPricePremiumsSuccess = (payload) => ({
  type: GET_KING_PRICE_PREMIUMS_SUCCESS,
  payload,
});

export const getKingPricePremiumsFailure = (error) => ({
  type: GET_KING_PRICE_PREMIUMS_FAILURE,
  error,
});

export const getKingPriceNewBusiness = (scheme) => ({
  type: GET_KING_PRICE_NEW_BUSINESS_REQUESTED,
  scheme,
});

export const getKingPriceNewBusinessSuccess = (payload) => ({
  type: GET_KING_PRICE_NEW_BUSINESS_SUCCESS,
  payload,
});

export const getKingPriceNewBusinessFailure = (error) => ({
  type: GET_KING_PRICE_NEW_BUSINESS_FAILURE,
  error,
});

export const getKingPriceAmendments = (scheme) => ({
  type: GET_KING_PRICE_AMENDMENTS_REQUESTED,
  scheme,
});

export const getKingPriceAmendmentsSuccess = (payload) => ({
  type: GET_KING_PRICE_AMENDMENTS_SUCCESS,
  payload,
});

export const getKingPriceAmendmentsFailure = (error) => ({
  type: GET_KING_PRICE_AMENDMENTS_FAILURE,
  error,
});

export const clearSafricanPremiumsRedux = () => ({
  type: CLEAR_SAFRICAN_PREMIUMS,
});

const initialState = {
  report: undefined,
  loading: undefined,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SAFRICAN_PREMIUMS_REQUESTED:
      return { ...state, report: undefined, loading: true };
    case GET_SAFRICAN_PREMIUMS_SUCCESS:
      return { ...state, report: action.payload.report, loading: false };
    case GET_SAFRICAN_PREMIUMS_FAILURE:
      return {
        ...state,
        report: undefined,
        error: action.error,
        loading: false,
      };
    case GET_KING_PRICE_PREMIUMS_REQUESTED:
      return { ...state, report: undefined, loading: true };
    case GET_KING_PRICE_PREMIUMS_SUCCESS:
      return { ...state, report: action.payload.report, loading: false };
    case GET_KING_PRICE_PREMIUMS_FAILURE:
      return {
        ...state,
        report: undefined,
        error: action.error,
        loading: false,
      };
    case GET_KING_PRICE_NEW_BUSINESS_REQUESTED:
      return { ...state, report: undefined, loading: true };
    case GET_KING_PRICE_NEW_BUSINESS_SUCCESS:
      return { ...state, report: action.payload.report, loading: false };
    case GET_KING_PRICE_NEW_BUSINESS_FAILURE:
      return {
        ...state,
        report: undefined,
        error: action.error,
        loading: false,
      };
    case GET_KING_PRICE_AMENDMENTS_REQUESTED:
      return { ...state, report: undefined, loading: true };
    case GET_KING_PRICE_AMENDMENTS_SUCCESS:
      return { ...state, report: action.payload.report, loading: false };
    case GET_KING_PRICE_AMENDMENTS_FAILURE:
      return {
        ...state,
        report: undefined,
        error: action.error,
        loading: false,
      };
    case CLEAR_SAFRICAN_PREMIUMS:
      return { ...state, report: undefined };
    default:
      return state;
  }
};
