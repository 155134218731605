import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import betterlifelogo from "../../assets/img/brands/betterlife-soft05.png";
import { loginUser } from "../../redux/saga/requests/user";
import { FailureAlert } from "../../components/CustomAlerts";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/ducks/user";
import { getMyOrg } from "../../redux/ducks/organisation";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader";

function isArray(value) {
  return Array.isArray(value);
}

export default function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);

  const loginSubmit = () => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    setLoading(true);
    if (email.trim() === "") {
      setErrorMessage("Email is required.");
      setErrorAlert(true);
      setLoading(false);
      return;
    }

    if (password === null || password.trim() === "") {
      setErrorMessage("Password is required.");
      setErrorAlert(true);
      setLoading(false);
      return;
    }

    loginUser({ email, password })
      .then((data) => {
        handleLambdaSubmit(data);
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setErrorAlert(true);
        setLoading(false);
      });

    const handleLambdaSubmit = async (data) => {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_PROFILE_ENDPOINT}?user=${data.attributes.address}`,
      });
      // const dashboardResponse = await axios({
      //   method: "GET",
      //   url: `${process.env.REACT_APP_DASHBOARD_ENDPOINT}/getOrgDashboard?org=${response.data.Item.organizationId}`,
      // });
      dispatch(setProfile(response.data.Item));
      // console.log("dashboard: ", dashboardResponse.data.Item.body);
      // dispatch(setDashboard(dashboardResponse.data.Item.body));
      if (isArray(response.data.Item.organizationId)) {
        dispatch(getMyOrg({ id: response.data.Item.organizationId[0].id }));
      } else {
        dispatch(getMyOrg({ id: response.data.Item.organizationId }));
      }

      history.push(`/dashboard`);
    };
  };

  const toggle = () => {
    setErrorAlert(!errorAlert);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ call submit function here
        loginSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Welcome</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>
      <FailureAlert
        isOpen={errorAlert}
        toggle={toggle}
        message={errorMessage}
      />
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={betterlifelogo}
                alt="BetterLife Software"
                className="img-fluid"
                width="150"
                height="132"
              />
            </div>

            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                />
              </FormGroup>
              <div className="text-center mt-3">
                {loading ? (
                  <Loader height={"vh-5"} />
                ) : (
                  <Button
                    color="primary"
                    size="lg"
                    onClick={() => loginSubmit()}
                  >
                    Sign in
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
