import { put, call } from "redux-saga/effects";
import {
  requestGetPolicyFolders,
  requestCreateFolder,
  requestGetOrgFolders,
  requestUpdateFolder,
  requestDeleteFolder,
} from "../requests/media";
import {
  createClaimFolderSuccess,
  createClaimFolderFailure,
  getClaimFoldersSuccess,
  getClaimFoldersFailure,
} from "../../ducks/media_claims";
import {
  createPolicyFolderSuccess,
  createPolicyFolderFailure,
  getPolicyFoldersSuccess,
  getPolicyFoldersFailure,
  updatePolicyFolderSuccess,
  updatePolicyFolderFailure,
  deletePolicyFolderSuccess,
  deletePolicyFolderFailure,
} from "../../ducks/media_policy";
import {
  createOrgFolderSuccess,
  createOrgFolderFailure,
  getOrgFoldersSuccess,
  getOrgFoldersFailure,
  updateOrgFolderSuccess,
  updateOrgFolderFailure,
  deleteOrgFolderSuccess,
  deleteOrgFolderFailure,
} from "../../ducks/media_org";

export function* handleCreatePolicyFolder(action) {
  try {
    const response = yield call(requestCreateFolder, action.payload);
    yield put(
      createPolicyFolderSuccess({ policyFolders: response.data.response })
    );
  } catch (err) {
    yield put(createPolicyFolderFailure({ error: err.message }));
  }
}

export function* handleCreateOrgFolder(action) {
  try {
    const response = yield call(requestCreateFolder, action.payload);
    yield put(createOrgFolderSuccess({ orgFolders: response.data.response }));
  } catch (err) {
    yield put(createOrgFolderFailure({ error: err.message }));
  }
}

export function* handleGetOrgFolders(action) {
  try {
    const response = yield call(requestGetOrgFolders, action.payload);
    yield put(getOrgFoldersSuccess(response.data.response));
  } catch (err) {
    yield put(getOrgFoldersSuccess([]));
    yield put(getOrgFoldersFailure({ error: err.message }));
  }
}

export function* handleGetPolicyFolders(action) {
  try {
    const response = yield call(requestGetPolicyFolders, action.payload);
    yield put(getPolicyFoldersSuccess({ policy: response.data.response }));
  } catch (err) {
    yield put(getPolicyFoldersFailure({ error: err.message }));
  }
}

export function* handleUpdateOrgFolder(action) {
  try {
    const response = yield call(requestUpdateFolder, action.payload);
    yield put(updateOrgFolderSuccess({ orgFolder: response.data.response }));
  } catch (err) {
    yield put(updateOrgFolderFailure({ error: err.message }));
  }
}

export function* handleUpdatePolicyFolder(action) {
  try {
    const response = yield call(requestUpdateFolder, action.payload);
    yield put(
      updatePolicyFolderSuccess({ policyFolder: response.data.response })
    );
  } catch (err) {
    yield put(updatePolicyFolderFailure({ error: err.message }));
  }
}

export function* handleDeleteOrgFolder(action) {
  try {
    const response = yield call(requestDeleteFolder, action.payload);
    yield put(deleteOrgFolderSuccess({ orgFolder: response.data.response }));
  } catch (err) {
    yield put(deleteOrgFolderFailure({ error: err.message }));
  }
}

export function* handleDeletePolicyFolder(action) {
  try {
    const response = yield call(requestDeleteFolder, action.payload);
    yield put(
      deletePolicyFolderSuccess({ policyFolder: response.data.response })
    );
  } catch (err) {
    yield put(deletePolicyFolderFailure({ error: err.message }));
  }
}
