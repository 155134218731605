import { put, call } from "redux-saga/effects";
import {
  requestCreatePremium,
  requestGetPremiumByMemberId,
  requestGetPremiumByAccountNumber,
  requestUpdatePremium,
  requestUpdateSinglePremium,
  requestGetPremiumByPaymentRef,
  requestGetPremiumByPolicyNumber,
  requestGetSinglePremiumByPolicyNumber,
} from "../requests/premiums";
import {
  createPremiumFailure,
  createPremiumSuccess,
  getPremiumByMemberIdSuccess,
  getPremiumByMemberIdFailure,
  getPremiumByAccountNumberSuccess,
  getPremiumByAccountNumberFailure,
  getPremiumByPaymentRefSuccess,
  getPremiumByPaymentRefFailure,
  getPremiumByPolicyNumberSuccess,
  getPremiumByPolicyNumberFailure,
  getSinglePremiumByPolicyNumberSuccess,
  getSinglePremiumByPolicyNumberFailure,
  updatePremiumSuccess,
  updatePremiumFailure,
  updateSinglePremiumSuccess,
  updateSinglePremiumFailure,
} from "../../ducks/premiums";

export function* handleCreatePremium(action) {
  try {
    const response = yield call(requestCreatePremium, action.payload);
    const { data } = response;
    yield put(createPremiumSuccess(data.response));
  } catch (err) {
    yield put(createPremiumFailure({ error: err.message }));
  }
}

export function* handleGetPremiumByMemberId(action) {
  try {
    const response = yield call(requestGetPremiumByMemberId, action.payload);
    const { data } = response;
    yield put(getPremiumByMemberIdSuccess(data.response));
  } catch (err) {
    yield put(getPremiumByMemberIdFailure({ error: err }));
  }
}

export function* handleGetPremiumByAccountNumber(action) {
  try {
    const response = yield call(
      requestGetPremiumByAccountNumber,
      action.payload
    );
    const { data } = response;
    yield put(getPremiumByAccountNumberSuccess(data.response));
  } catch (err) {
    yield put(getPremiumByAccountNumberFailure({ error: err }));
  }
}

export function* handleGetPremiumByPaymentRef(action) {
  try {
    const response = yield call(requestGetPremiumByPaymentRef, action.payload);
    const { data } = response;
    yield put(getPremiumByPaymentRefSuccess(data.response));
  } catch (err) {
    yield put(getPremiumByPaymentRefFailure({ error: err }));
  }
}

export function* handleGetPremiumByPolicyNumber(action) {
  try {
    const response = yield call(
      requestGetPremiumByPolicyNumber,
      action.payload
    );
    const { data } = response;
    yield put(getPremiumByPolicyNumberSuccess(data.response));
  } catch (err) {
    yield put(getPremiumByPolicyNumberFailure({ error: err }));
  }
}

export function* handleGetsSinglePremiumByPolicyNumber(action) {
  try {
    const response = yield call(
      requestGetSinglePremiumByPolicyNumber,
      action.payload
    );
    const { data } = response;
    yield put(getSinglePremiumByPolicyNumberSuccess(data.response));
  } catch (err) {
    yield put(getSinglePremiumByPolicyNumberFailure({ error: err }));
  }
}

export function* handleUpdatePremium(action) {
  try {
    const response = yield call(requestUpdatePremium, action.payload);
    const { data } = response;
    yield put(updatePremiumSuccess(data.response));
  } catch (err) {
    yield put(updatePremiumFailure({ error: err }));
  }
}

export function* handleUpdateSinglePremium(action) {
  try {
    const response = yield call(requestUpdateSinglePremium, action.payload);
    const { data } = response;
    yield put(updateSinglePremiumSuccess(data.response));
  } catch (err) {
    yield put(updateSinglePremiumFailure({ error: err }));
  }
}
