import { put, call } from "redux-saga/effects";
import {
  requestCreatePolicy,
  requestGetPolicyById,
  requestGetPoliciesBySchemeId,
  requestUpdatePolicyStatus,
  requestUpdatePolicy,
  requestDeletePolicy,
} from "../requests/policy";
import {
  createPolicySuccess,
  createPolicyFailure,
  getPolicySuccess,
  getPolicyFailure,
  getPoliciesBySchemeSuccess,
  getPoliciesBySchemeFailure,
  updatePolicyStatusSuccess,
  updatePolicyStatusFailure,
  updatePolicySuccess,
  updatePolicyFailure,
  deletePolicySuccess,
  deletePolicyFailure,
} from "../../ducks/policy";

export function* handleCreatePolicy(action) {
  try {
    const response = yield call(requestCreatePolicy, action.payload);
    const { data } = response;
    yield put(createPolicySuccess({ policy: data.response.scheme }));
  } catch (err) {
    yield put(createPolicyFailure({ error: err.message }));
  }
}

export function* handleGetPolicyById(action) {
  try {
    console.log("handleGetPolicyById");
    const response = yield call(requestGetPolicyById, action.payload);
    console.log("response.data.response:", response.data.response);
    yield put(getPolicySuccess({ policy: response.data.response }));
  } catch (err) {
    yield put(getPolicyFailure({ error: err.message }));
  }
}

export function* handleGetPoliciesBySchemeId(action) {
  try {
    const response = yield call(requestGetPoliciesBySchemeId, action.schemeID);
    yield put(getPoliciesBySchemeSuccess({ policies: response.data.response }));
  } catch (err) {
    yield put(getPoliciesBySchemeFailure({ error: err.message }));
  }
}

export function* handleUpdatePolicy(action) {
  try {
    const response = yield call(requestUpdatePolicy, action.payload);
    const { data } = response;
    yield put(updatePolicySuccess({ policy: data.response }));
  } catch (err) {
    yield put(updatePolicyFailure({ error: err.message }));
  }
}

export function* handleUpdatePolicyStatus(action) {
  try {
    const response = yield call(requestUpdatePolicyStatus, action.payload);
    const { data } = response;
    console.log("data: ", data);
    yield put(updatePolicyStatusSuccess({ policy: data.response }));
  } catch (err) {
    yield put(updatePolicyStatusFailure({ error: err.message }));
  }
}

export function* handleDeletePolicy(action) {
  try {
    const response = yield call(requestDeletePolicy, action.payload);
    const { data } = response;
    yield put(deletePolicySuccess(action.payload));
  } catch (err) {
    yield put(deletePolicyFailure({ error: err.message }));
  }
}
