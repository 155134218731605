export const CREATE_POLICY_REQUESTED = "CREATE_POLICY_REQUESTED";
export const CREATE_POLICY_SUCCESS = "CREATE_POLICY_SUCCESS";
export const CREATE_POLICY_FAILURE = "CREATE_POLICY_FAILURE";
export const UPDATE_POLICY_REQUESTED = "UPDATE_POLICY_REQUESTED";
export const UPDATE_POLICY_SUCCESS = "UPDATE_POLICY_SUCCESS";
export const UPDATE_POLICY_FAILURE = "UPDATE_POLICY_FAILURE";
export const UPDATE_POLICY_STATUS_REQUESTED = "UPDATE_POLICY_STATUS_REQUESTED";
export const UPDATE_POLICY_STATUS_SUCCESS = "UPDATE_POLICY_STATUS_SUCCESS";
export const UPDATE_POLICY_STATUS_FAILURE = "UPDATE_POLICY_STATUS_FAILURE";
export const UPDATE_REDUX_POLICY_REQUESTED = "UPDATE_REDUX_POLICY_REQUESTED";
export const GET_POLICY_REQUESTED = "GET_POLICY_REQUESTED";
export const GET_POLICY_SUCCESS = "GET_POLICY_SUCCESS";
export const GET_POLICY_FAILURE = "GET_POLICY_FAILURE";
export const GET_POLICIES_REQUESTED = "GET_POLICIES_REQUESTED";
export const GET_POLICIES_SUCCESS = "GET_POLICIES_SUCCESS";
export const GET_POLICIES_FAILURE = "GET_POLICIES_FAILURE";
export const GET_POLICIES_SCHEMEID_REQUESTED =
  "GET_POLICIES_SCHEMEID_REQUESTED";
export const GET_POLICIES_SCHEMEID_SUCCESS = "GET_POLICIES_SCHEMEID_SUCCESS";
export const GET_POLICIES_SCHEMEID_FAILURE = "GET_POLICIES_SCHEMEID_FAILURE";
export const GET_POLICIES_REDUX_REQUESTED = "GET_POLICIES_REDUX_REQUESTED";
export const GET_POLICIES_REDUX_SUCCESS = "GET_POLICIES_REDUX_SUCCESS";
export const GET_POLICIES_REDUX_FAILURE = "GET_POLICIES_REDUX_FAILURE";
export const GET_POLICY_BY_ID_REDUX_REQUESTED =
  "GET_POLICY_BY_ID_REDUX_REQUESTED";
export const GET_ALL_POLICIES_REQUESTED = "GET_ALL_POLICIES_REQUESTED";
export const GET_ALL_POLICIES_SUCCESS = "GET_ALL_POLICIES_SUCCESS";
export const GET_ALL_POLICIES_FAILURE = "GET_ALL_POLICIES_FAILURE";
export const DELETE_POLICY_REQUESTED = "DELETE_POLICY_REQUESTED";
export const DELETE_POLICY_SUCCESS = "DELETE_POLICY_SUCCESS";
export const DELETE_POLICY_FAILURE = "DELETE_POLICY_FAILURE";
export const CLEAR_POLICIES = "CLEAR_POLICIES";
export const CLEAR_POLICY = "CLEAR_POLICY";

export const createPolicy = (payload) => ({
  type: CREATE_POLICY_REQUESTED,
  payload,
});
export const createPolicySuccess = (payload) => ({
  type: CREATE_POLICY_SUCCESS,
  payload,
});
export const createPolicyFailure = (error) => ({
  type: CREATE_POLICY_FAILURE,
  error,
});

export const updatePolicy = (payload) => ({
  type: UPDATE_POLICY_REQUESTED,
  payload,
});

export const updatePolicySuccess = (payload) => ({
  type: UPDATE_POLICY_SUCCESS,
  payload,
});

export const updatePolicyFailure = (error) => ({
  type: UPDATE_POLICY_FAILURE,
  error,
});

export const updatePolicyStatus = (payload) => ({
  type: UPDATE_POLICY_STATUS_REQUESTED,
  payload,
});

export const updatePolicyStatusSuccess = (payload) => ({
  type: UPDATE_POLICY_STATUS_SUCCESS,
  payload,
});

export const updatePolicyStatusFailure = (error) => ({
  type: UPDATE_POLICY_STATUS_FAILURE,
  error,
});

export const updateReduxPolicy = (payload) => ({
  type: UPDATE_REDUX_POLICY_REQUESTED,
  payload,
});

export const getPolicyById = (payload) => ({
  type: GET_POLICY_REQUESTED,
  payload,
});

export const getPolicySuccess = (payload) => ({
  type: GET_POLICY_SUCCESS,
  payload,
});

export const getPolicyFailure = (error) => ({
  type: GET_POLICY_FAILURE,
  error,
});

export const clearPolicy = () => ({
  type: CLEAR_POLICY,
});

export const getPolicies = (orgID) => ({
  type: GET_POLICIES_REQUESTED,
  orgID,
});

export const getPoliciesSuccess = (payload) => ({
  type: GET_POLICIES_SUCCESS,
  payload,
});

export const getPoliciesFailure = (error) => ({
  type: GET_POLICIES_FAILURE,
  error,
});

export const getPoliciesByScheme = (schemeID) => ({
  type: GET_POLICIES_SCHEMEID_REQUESTED,
  schemeID,
});

export const getPoliciesBySchemeSuccess = (payload) => ({
  type: GET_POLICIES_SCHEMEID_SUCCESS,
  payload,
});

export const getPoliciesBySchemeFailure = (error) => ({
  type: GET_POLICIES_SCHEMEID_FAILURE,
  error,
});

export const getPolicyByIdRedux = (payload) => ({
  type: GET_POLICY_BY_ID_REDUX_REQUESTED,
  payload,
});

export const getPoliciesRedux = () => ({
  type: GET_POLICIES_REDUX_REQUESTED,
});

export const getPoliciesReduxSuccess = (payload) => ({
  type: GET_POLICIES_REDUX_SUCCESS,
  payload,
});

export const getPoliciesReduxFailure = (error) => ({
  type: GET_POLICIES_REDUX_FAILURE,
  error,
});

export const getAllPolicies = () => ({
  type: GET_ALL_POLICIES_REQUESTED,
});

export const getAllPoliciesSuccess = (payload) => ({
  type: GET_ALL_POLICIES_REQUESTED,
  payload,
});

export const getAllPoliciesFailure = (error) => ({
  type: GET_ALL_POLICIES_FAILURE,
  error,
});

export const deletePolicy = (payload) => ({
  type: DELETE_POLICY_REQUESTED,
  payload,
});
export const deletePolicySuccess = (payload) => ({
  type: DELETE_POLICY_SUCCESS,
  payload,
});
export const deletePolicyFailure = (error) => ({
  type: DELETE_POLICY_FAILURE,
  error,
});

export const clearPolicies = () => ({
  type: CLEAR_POLICIES,
});

const initialState = {
  policy: undefined,
  policies: undefined,
  path: undefined,
  loading: false,
  loadingPolicy: true,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POLICY_REQUESTED:
      return { ...state, loading: true };
    case CREATE_POLICY_SUCCESS:
      return { ...state, policy: action.payload, loading: false };
    case CREATE_POLICY_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_POLICY_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_POLICY_SUCCESS:
      return { ...state, policy: action.payload.policy, loading: false };
    case UPDATE_POLICY_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_POLICY_STATUS_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_POLICY_STATUS_SUCCESS:
      state.loadingPolicy = true;
      const result1 = state.policies.filter(
        (item) => item.id === action.payload.id
      );
      return { ...state, policy: result1, loading: false };
    case UPDATE_POLICY_STATUS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_REDUX_POLICY_REQUESTED:
      return { ...state, policy: action.payload };
    case GET_POLICY_REQUESTED:
      return { ...state, loadingPolicy: true };
    case GET_POLICY_SUCCESS:
      return { ...state, policy: action.payload.policy, loadingPolicy: false };
    case GET_POLICY_FAILURE:
      return { ...state, error: action.error, loadingPolicy: false };
    case GET_POLICY_BY_ID_REDUX_REQUESTED:
      state.loadingPolicy = true;
      const result = state.policies.filter(
        (item) => item.id === action.payload.id
      );
      return {
        ...state,
        policy: result[0],
        path: action.payload.path,
        loadingPolicy: false,
      };
    case GET_POLICIES_REQUESTED:
      return { ...state, loading: true };
    case GET_POLICIES_SUCCESS:
      return { ...state, policies: action.payload.policies, loading: false };
    case GET_POLICIES_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_POLICIES_SCHEMEID_REQUESTED:
      return { ...state, loading: true };
    case GET_POLICIES_SCHEMEID_SUCCESS:
      return { ...state, policies: action.payload.policies, loading: false };
    case GET_POLICIES_SCHEMEID_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_POLICIES_REDUX_REQUESTED:
      return { ...state.policies };
    case GET_ALL_POLICIES_REQUESTED:
      return { ...state, loading: true };
    case GET_ALL_POLICIES_SUCCESS:
      return { ...state, policies: action.payload, loading: false };
    case GET_ALL_POLICIES_FAILURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_POLICY_REQUESTED:
      return { ...state, loading: true };
    case DELETE_POLICY_SUCCESS:
      return {
        ...state,
        policies: state.policies.filter((item) => item.id !== action.payload),
        loading: false,
      };
    case DELETE_POLICY_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CLEAR_POLICIES:
      return { ...state, policies: undefined };
    case CLEAR_POLICY:
      return { ...state, policy: undefined, path: undefined };
    default:
      return state;
  }
};
