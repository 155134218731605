import axios from "axios";

export function requestCreateUnderwriter(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/underwriter/create`,
    data,
  });
}

export function requestCreateUnderwriterRates(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/underwriter/rate`,
    data,
  });
}

export function requestGetUnderwritersByOrgID(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/underwriters/${data}`,
  });
}

export function requestGetUnderwriter(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/underwriter/${data}`,
  });
}

// all underwriter rates in org
export function requestGetUnderwriterRatesByOrgId(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/underwriter/rates/${data}`,
  });
}

export function requestDeleteUnderwriter(data) {
  return axios.request({
    method: "delete",
    url: `${process.env.REACT_APP_DELETE_ENDPOINT}?type=underwriter&id=${data.id}`,
  });
}

// specific underwriter rate
export function requestGetUnderwriterRatesById(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/underwriter/rate/${data}`,
  });
}

export function requestGetUnderwriterRatesByUnderwriterId(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/underwriters/rates/${data}`,
  });
}

export function requestUpdateUnderwriterRates(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/underwriter/rate/update`,
    data,
  });
}

export function requestUpdateUnderwriter(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/underwriter/update`,
    data,
  });
}

export function requestDeleteUnderwriterRates(data) {
  return axios.request({
    method: "delete",
    url: `${process.env.REACT_APP_DELETE_ENDPOINT}?type=underwriter_rate&id=${data.id}`,
  });
}

export function requestCloneUnderwriterRatesById(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/underwriter/rate/clone/${data}`,
  });
}
