import async from "../components/Async";
import {
  faBook,
  faChartPie,
  faDesktop,
  faFile,
  faFlask,
  faHeart,
  faHome,
  faMapMarkerAlt,
  faSignInAlt,
  faUserFriends,
  faBriefcase,
  faAddressBook,
  faBuilding,
  faDonate,
  faMapMarkedAlt,
  faFileLines,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import ResetVerification from "../pages/auth/ResetVerification";

// Layouts
import SidebarLeft from "../pages/layouts/SidebarLeft";
import ThemeModern from "../pages/layouts/ThemeModern";

// UI Elements
import Alerts from "../pages/ui-elements/Alerts";
import Buttons from "../pages/ui-elements/Buttons";
import Cards from "../pages/ui-elements/Cards";
import General from "../pages/ui-elements/General";
import Grid from "../pages/ui-elements/Grid";
import Modals from "../pages/ui-elements/Modals";
//import Notifications from "../pages/ui-elements/Notifications";
import Tabs from "../pages/ui-elements/Tabs";
import Typography from "../pages/ui-elements/Typography";

// Pages
const Settings = async(() => import("../pages/pages/Settings"));
const Clients = async(() => import("../pages/pages/Clients"));
const Invoice = async(() => import("../pages/pages/Invoice"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Chat = async(() => import("../pages/pages/Chat"));

// Documentation
const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
const Plugins = async(() => import("../pages/docs/Plugins"));
const Changelog = async(() => import("../pages/docs/Changelog"));

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));
const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
const IonIcons = async(() => import("../pages/icons/IonIcons"));
const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
//sconst Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

//Custom components
const Policies = async(() => import("../pages/policies/Policies"));
const CreatePolicy = async(() => import("../pages/policies/CreatePolicy"));
const CreatePolicyPreCheck = async(() =>
  import("../pages/policies/CreatePolicyPreCheck")
);
const MaintainPolicy = async(() => import("../pages/policies/MaintainPolicy"));
const BurialSociety = async(() => import("../pages/groups/BurialSociety"));
const Church = async(() => import("../pages/groups/Church"));
const CompanyEmployer = async(() => import("../pages/groups/CompanyEmployer"));
const FuneralParlour = async(() => import("../pages/groups/FuneralParlour"));
const TradeUnion = async(() => import("../pages/groups/TradeUnion"));
const Trust = async(() => import("../pages/groups/Trust"));
const EmptyState = async(() => import("../components/EmptyState"));

//Organisation components
const CreateOrganization = async(() =>
  import("../pages/organisation/CreateOrganization")
);
const MaintainOrganisation = async(() =>
  import("../pages/organisation/MaintainOrganisation")
);
const MaintainGroupDetails = async(() =>
  import("../pages/organisation/MaintainGroupDetails")
);
const SchemesList = async(() => import("../pages/schemes/SchemesList"));
const CreateScheme = async(() => import("../pages/schemes/CreateScheme"));
const CreateGroupScheme = async(() =>
  import("../pages/schemes/CreateGroupScheme")
);
const MaintainScheme = async(() => import("../pages/schemes/MaintainScheme"));
const Underwriter = async(() => import("../pages/underwriter/Underwiters"));
const CreateRates = async(() => import("../pages/underwriter/CreateRates"));
const CreateUnderwriter = async(() =>
  import("../pages/underwriter/CreateUnderwriter")
);

const MaintainUnderwriter = async(() =>
  import("../pages/underwriter/MaintainUnderwriter")
);
const ViewUnderwriterRates = async(() =>
  import("../pages/underwriter/ViewUnderwriterRates")
);
const MaintainUnderwriterRates = async(() =>
  import("../pages/underwriter/MaintainUnderwriterRates")
);

const CreateUser = async(() =>
  import("../pages/organisation/components/CreateUser")
);

const SchemeReport = async(() => import("../pages/reports/SchemeReport"));
const NewBusinessReport = async(() =>
  import("../pages/reports/NewBusinessReport")
);
const PremiumReport = async(() => import("../pages/reports/PremiumReport"));
const AmendmentReport = async(() => import("../pages/reports/AmendmentReport"));

const PaymentsChannel = async(() =>
  import("../pages/payments/PaymentsChannel")
);

const ParticipationCerts = async(() =>
  import("../components/ParticipationCerts")
);

const Notifications = async(() =>
  import("../pages/notifications/Notifications")
);

const AuditLogs = async(() => import("../pages/auditLogs/AuditLogs"));

const authRoutes = {
  path: "/",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/verification",
      name: "Reset Verification",
      component: ResetVerification,
    },
    {
      path: "/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/500",
      name: "500 Page",
      component: Page500,
    },
  ],
};

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  icon: faHome,
  component: Default,
  children: null,
};

const organisationRoutes = {
  path: "/organisation",
  name: "Organisations",
  header: "Administration",
  icon: faBuilding,
  children: [
    {
      path: "/organisation/create",
      name: "Create Client's Organisation",
      component: CreateOrganization,
    },
    {
      path: "/organisation/myorg",
      name: "My Organisation",
      component: MaintainOrganisation,
    },
  ],
};

const nonFSPorganisationRoutes = {
  path: "/organisation",
  name: "Organisations",
  header: "Administration",
  icon: faBuilding,
  children: [
    {
      path: "/organisation/myorg",
      name: "My Organisation",
      component: MaintainOrganisation,
    },
  ],
};

const underwriterRoutes = {
  path: "/underwriter",
  name: "Underwriters",
  icon: faAddressBook,
  children: [
    {
      path: "/underwriter/create",
      name: "Add New Underwriter",
      component: CreateUnderwriter,
    },
    {
      path: "/underwriter/all",
      name: "Current Underwriters",
      component: Underwriter,
    },
  ],
};

const policyRoutes = {
  path: "/schemes",
  name: "Individual Schemes",
  icon: faAddressBook,
  children: [
    {
      path: "/schemes/all",
      name: "All Schemes",
      component: SchemesList,
    },
    {
      path: "/schemes/create",
      name: "Add New Schemes",
      component: CreateScheme,
    },
  ],
};

const nonFSPpolicyRoutes = {
  path: "/schemes",
  name: "Schemes",
  header: "Schemes",
  icon: faAddressBook,
  children: [
    {
      path: "/schemes/all",
      name: "All Schemes",
      component: SchemesList,
    },
    {
      path: "/schemes/create",
      name: "Add New Schemes",
      component: CreateScheme,
    },
  ],
};

const societiesRoutes = {
  path: "/groups",
  name: "Group Schemes",
  header: "Schemes",
  icon: faUserFriends,
  children: [
    {
      path: "/groups/burialSociety",
      name: "Burial Society",
      component: BurialSociety,
    },
    {
      path: "/groups/funeralParlour",
      name: "Funeral Parlour",
      component: FuneralParlour,
    },
    {
      path: "/groups/tradeUnion",
      name: "Trade Union",
      component: TradeUnion,
    },
    {
      path: "/groups/church",
      name: "Church",
      component: Church,
    },
    {
      path: "/groups/company",
      name: "Company/Employer",
      component: CompanyEmployer,
    },
    {
      path: "/groups/trust",
      name: "Trust",
      component: Trust,
    },
  ],
};

const clientsRoutes = {
  path: "/clients",
  name: "Clients",
  icon: faBriefcase,
  children: [
    {
      path: "/policies/clients",
      name: "All Clients",
      component: Settings,
    },
    {
      path: "/policies/create",
      name: "New Client",
      component: Clients,
    },
  ],
};

const safricanReportRoutes = {
  path: "/report/safrican",
  name: "Safrican Reports",
  icon: faChartBar,
  children: [
    // {
    //   path: "/report/kingprice/membership",
    //   name: "Membership Report",
    //   component: MembershipReport,
    // },
    {
      path: "/report/safrican",
      name: "Premium Report",
      component: SchemeReport,
    },
  ],
};

const kingPriceReportRoutes = {
  path: "/report/kingprice",
  name: "King Price Reports",
  header: "Reports",
  icon: faChartBar,
  children: [
    // {
    //   path: "/report/membership",
    //   name: "Membership Report",
    //   component: MembershipReport,
    // },
    {
      path: "/report/kingprice/newbusiness",
      name: "New Business Report",
      component: NewBusinessReport,
    },
    {
      path: "/report/kingprice/premiums",
      name: "Premium Report",
      component: PremiumReport,
    },
    {
      path: "/report/kingprice/amendments",
      name: "Amendments Report",
      component: AmendmentReport,
    },
  ],
};

const premiumsRoutes = {
  path: "/operations",
  name: "Premium Records",
  header: "Premiums",
  icon: faFile,
  children: [
    {
      path: "/operations/premiums",
      name: "Maintain Premiums",
      component: PaymentsChannel,
    },
  ],
};

const operationsRoutes = {
  path: "/audit",
  name: "Audit Logs",
  header: "Operations",
  component: AuditLogs,
  children: null,
};

const notificationsRoutes = {
  path: "/notifications",
  name: "Notifications",
  component: Notifications,
  children: null,
};

const groupPrivateRoutes = {
  path: "/group",
  name: "Private",
  children: [
    {
      path: "/group/:organisationID",
      name: "Maintain Group Details",
      component: MaintainGroupDetails,
    },
    {
      path: "/group/:organisationID/scheme/create",
      name: "Create Group Scheme",
      component: CreateGroupScheme,
    },
  ],
};

const organisationPrivateRoutes = {
  path: "/organisation",
  name: "Private",
  children: [
    {
      path: "/organisation/users/:userID",
      name: "User's Details",
      component: CreateUser,
    },
    {
      path: "/organisation/test",
      name: "pdf test",
      component: ParticipationCerts,
    },
  ],
};

const underwriterPrivateRoutes = {
  path: "/underwriter",
  name: "Private",
  children: [
    {
      path: "/underwriter/rate/create/:underwriterId",
      name: "Create Underwriter Rates",
      component: CreateRates,
    },
    {
      path: "/underwriter/rate/maintain/:underwriterId",
      name: "Create Underwriter Rates",
      component: CreateRates,
    },
    {
      path: "/underwriter/rate/:underwriterId",
      name: "Underwriter Rates",
      component: ViewUnderwriterRates,
    },
    {
      path: "/underwriter/:underwriterId/rate/:rateId",
      name: "Maintain Underwriter Rates",
      component: MaintainUnderwriterRates,
    },
    {
      path: "/underwriter/:underwriterId",
      name: "Maintain Underwriter",
      component: MaintainUnderwriter,
    },
  ],
};

const maintainPolicyPrivateRoutes = {
  path: "/policy",
  name: "Private",
  children: [
    {
      path: "/policy/:policyNumber",
      name: "Maintain Policy Details",
      component: MaintainPolicy,
    },
    {
      path: "/policy/all/:orgID/:schemeID",
      name: "All Policies",
      component: Policies,
    },
  ],
};

const createPolicyPrivateRoutes = {
  path: "/policy",
  name: "Private",
  children: [
    {
      path: "/policy/create/:orgID/:schemeID",
      name: "Create New Policy",
      component: CreatePolicy,
    },
    {
      path: "/policy/validate/:underwriterRatesID/:schemeID",
      name: "Policy Validation",
      component: CreatePolicyPreCheck,
    },
  ],
};

const createSchemePrivateRoutes = {
  path: "/schemes",
  name: "Private",
  children: [
    {
      path: "/schemes/create/:orgID",
      name: "Create New Scheme",
      component: CreateScheme,
    },
    {
      path: "/scheme/:schemeID",
      name: "Maintain Scheme",
      component: MaintainScheme,
    },
  ],
};

export const defaultDashboard = [
  dashboardRoutes,
  societiesRoutes,
  policyRoutes,
  organisationRoutes,
  kingPriceReportRoutes,
  safricanReportRoutes,
  premiumsRoutes,
  groupPrivateRoutes,
  maintainPolicyPrivateRoutes,
  createPolicyPrivateRoutes,
  createSchemePrivateRoutes,
  underwriterRoutes,
  underwriterPrivateRoutes,
  organisationPrivateRoutes,
  operationsRoutes,
  notificationsRoutes,
];

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  //premiumCollectionRoutes,
  //businessAreaRoutes,
  societiesRoutes,
  policyRoutes,
  organisationRoutes,
  underwriterRoutes,
  kingPriceReportRoutes,
  safricanReportRoutes,
  premiumsRoutes,
  operationsRoutes,
  notificationsRoutes,
];

export const nonFSPdashboard = [
  dashboardRoutes,
  nonFSPpolicyRoutes,
  nonFSPorganisationRoutes,
  //reportingRoutes,
  kingPriceReportRoutes,
  safricanReportRoutes,
  premiumsRoutes,
  operationsRoutes,
  notificationsRoutes,
];

export const page = [authRoutes];

// All routes
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  dashboardRoutes,
  societiesRoutes,
  policyRoutes,
  organisationRoutes,
  underwriterRoutes,
  kingPriceReportRoutes,
  safricanReportRoutes,
  premiumsRoutes,
  operationsRoutes,
  notificationsRoutes,
];
