export const CREATE_PREMIUM_REQUESTED = "CREATE_PREMIUM_REQUESTED";
export const CREATE_PREMIUM_SUCCESS = "CREATE_PREMIUM_SUCCESS";
export const CREATE_PREMIUM_FAILURE = "CREATE_PREMIUM_FAILURE";
export const UPDATE_PREMIUM_REQUESTED = "UPDATE_PREMIUM_REQUESTED";
export const UPDATE_PREMIUM_SUCCESS = "UPDATE_PREMIUM_SUCCESS";
export const UPDATE_PREMIUM_FAILURE = "UPDATE_PREMIUM_FAILURE";
export const UPDATE_SINGLE_PREMIUM_REQUESTED =
  "UPDATE_SINGLE_PREMIUM_REQUESTED";
export const UPDATE_SINGLE_PREMIUM_SUCCESS = "UPDATE_SINGLE_PREMIUM_SUCCESS";
export const UPDATE_SINGLE_PREMIUM_FAILURE = "UPDATE_SINGLE_PREMIUM_FAILURE";
export const GET_PREMIUMS_BY_MEMBER_ID_REQUESTED =
  "GET_PREMIUMS_BY_MEMBER_ID_REQUESTED";
export const GET_PREMIUMS_BY_MEMBER_ID_SUCCESS =
  "GET_PREMIUMS_BY_MEMBER_ID_SUCCESS";
export const GET_PREMIUMS_BY_MEMBER_ID_FAILURE =
  "GET_PREMIUMS_BY_MEMBER_ID_FAILURE";
export const GET_PREMIUMS_BY_ACCOUNT_NUMBER_REQUESTED =
  "GET_PREMIUMS_BY_ACCOUNT_NUMBER_REQUESTED";
export const GET_PREMIUMS_BY_ACCOUNT_NUMBER_SUCCESS =
  "GET_PREMIUMS_BY_ACCOUNT_NUMBER_SUCCESS";
export const GET_PREMIUMS_BY_ACCOUNT_NUMBER_FAILURE =
  "GET_PREMIUMS_BY_ACCOUNT_NUMBER_FAILURE";
export const GET_PREMIUMS_BY_PAYMENT_REF_REQUESTED =
  "GET_PREMIUMS_BY_PAYMENT_REF_REQUESTED";
export const GET_PREMIUMS_BY_PAYMENT_REF_SUCCESS =
  "GET_PREMIUMS_BY_PAYMENT_REF_SUCCESS";
export const GET_PREMIUMS_BY_PAYMENT_REF_FAILURE =
  "GET_PREMIUMS_BY_PAYMENT_REF_FAILURE";
export const GET_PREMIUMS_BY_POLICY_NUMBER_REQUESTED =
  "GET_PREMIUMS_BY_POLICY_NUMBER_REQUESTED";
export const GET_PREMIUMS_BY_POLICY_NUMBER_SUCCESS =
  "GET_PREMIUMS_BY_POLICY_NUMBER_SUCCESS";
export const GET_PREMIUMS_BY_POLICY_NUMBER_FAILURE =
  "GET_PREMIUMS_BY_POLICY_NUMBER_FAILURE";
export const GET_PREMIUM_BY_POLICY_NUMBER_REQUESTED =
  "GET_PREMIUM_BY_POLICY_NUMBER_REQUESTED";
export const GET_PREMIUM_BY_POLICY_NUMBER_SUCCESS =
  "GET_PREMIUM_BY_POLICY_NUMBER_SUCCESS";
export const GET_PREMIUM_BY_POLICY_NUMBER_FAILURE =
  "GET_PREMIUM_BY_POLICY_NUMBER_FAILURE";
export const CLEAR_PREMIUMS = "CLEAR_PREMIUMS";

export const createPremium = (payload) => ({
  type: CREATE_PREMIUM_REQUESTED,
  payload,
});
export const createPremiumSuccess = (payload) => ({
  type: CREATE_PREMIUM_SUCCESS,
  payload,
});
export const createPremiumFailure = (error) => ({
  type: CREATE_PREMIUM_FAILURE,
  error,
});

export const updatePremium = (payload) => ({
  type: UPDATE_PREMIUM_REQUESTED,
  payload,
});

export const updatePremiumSuccess = (payload) => ({
  type: UPDATE_PREMIUM_SUCCESS,
  payload,
});

export const updatePremiumFailure = (error) => ({
  type: UPDATE_PREMIUM_FAILURE,
  error,
});

export const updateSinglePremium = (payload) => ({
  type: UPDATE_SINGLE_PREMIUM_REQUESTED,
  payload,
});

export const updateSinglePremiumSuccess = (payload) => ({
  type: UPDATE_SINGLE_PREMIUM_SUCCESS,
  payload,
});

export const updateSinglePremiumFailure = (error) => ({
  type: UPDATE_SINGLE_PREMIUM_FAILURE,
  error,
});

export const getPremiumByMemberId = (payload) => ({
  type: GET_PREMIUMS_BY_MEMBER_ID_REQUESTED,
  payload,
});

export const getPremiumByMemberIdSuccess = (payload) => ({
  type: GET_PREMIUMS_BY_MEMBER_ID_SUCCESS,
  payload,
});

export const getPremiumByMemberIdFailure = (error) => ({
  type: GET_PREMIUMS_BY_MEMBER_ID_FAILURE,
  error,
});

export const getPremiumByAccountNumber = (payload) => ({
  type: GET_PREMIUMS_BY_ACCOUNT_NUMBER_REQUESTED,
  payload,
});

export const getPremiumByAccountNumberSuccess = (payload) => ({
  type: GET_PREMIUMS_BY_ACCOUNT_NUMBER_SUCCESS,
  payload,
});

export const getPremiumByAccountNumberFailure = (error) => ({
  type: GET_PREMIUMS_BY_ACCOUNT_NUMBER_FAILURE,
  error,
});

export const getPremiumByPaymentRef = (payload) => ({
  type: GET_PREMIUMS_BY_PAYMENT_REF_REQUESTED,
  payload,
});

export const getPremiumByPaymentRefSuccess = (payload) => ({
  type: GET_PREMIUMS_BY_PAYMENT_REF_SUCCESS,
  payload,
});

export const getPremiumByPaymentRefFailure = (error) => ({
  type: GET_PREMIUMS_BY_PAYMENT_REF_FAILURE,
  error,
});

export const getPremiumByPolicyNumber = (payload) => ({
  type: GET_PREMIUMS_BY_POLICY_NUMBER_REQUESTED,
  payload,
});

export const getPremiumByPolicyNumberSuccess = (payload) => ({
  type: GET_PREMIUMS_BY_POLICY_NUMBER_SUCCESS,
  payload,
});

export const getPremiumByPolicyNumberFailure = (error) => ({
  type: GET_PREMIUMS_BY_POLICY_NUMBER_FAILURE,
  error,
});

export const getSinglePremiumByPolicyNumber = (payload) => ({
  type: GET_PREMIUM_BY_POLICY_NUMBER_REQUESTED,
  payload,
});

export const getSinglePremiumByPolicyNumberSuccess = (payload) => ({
  type: GET_PREMIUM_BY_POLICY_NUMBER_SUCCESS,
  payload,
});

export const getSinglePremiumByPolicyNumberFailure = (error) => ({
  type: GET_PREMIUM_BY_POLICY_NUMBER_FAILURE,
  error,
});

export const clearPremiums = () => ({
  type: CLEAR_PREMIUMS,
});

const initialState = {
  premiums: undefined,
  premium: undefined,
  loading: false,
  success: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PREMIUM_REQUESTED:
      return { ...state, loading: true, success: false };
    case CREATE_PREMIUM_SUCCESS:
      return {
        ...state,
        premium: action.payload,
        loading: false,
        success: true,
      };
    case CREATE_PREMIUM_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_PREMIUM_REQUESTED:
      return { ...state, loading: true, success: false };
    case UPDATE_PREMIUM_SUCCESS:
      return {
        ...state,
        premium: action.payload,
        loading: false,
        success: "Premiums successfully updated",
      };
    case UPDATE_PREMIUM_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_SINGLE_PREMIUM_REQUESTED:
      return { ...state, loading: true, success: false };
    case UPDATE_SINGLE_PREMIUM_SUCCESS:
      return {
        ...state,
        premium: action.payload,
        loading: false,
        success: "Premium successfully updated",
      };
    case UPDATE_SINGLE_PREMIUM_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_PREMIUMS_BY_MEMBER_ID_REQUESTED:
      return { ...state, loading: true, success: false };
    case GET_PREMIUMS_BY_MEMBER_ID_SUCCESS:
      return { ...state, premiums: action.payload, loading: false };
    case GET_PREMIUMS_BY_MEMBER_ID_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_PREMIUMS_BY_ACCOUNT_NUMBER_REQUESTED:
      return { ...state, loading: true, success: false };
    case GET_PREMIUMS_BY_ACCOUNT_NUMBER_SUCCESS:
      return { ...state, premiums: action.payload, loading: false };
    case GET_PREMIUMS_BY_ACCOUNT_NUMBER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_PREMIUMS_BY_PAYMENT_REF_REQUESTED:
      return { ...state, loading: true, success: false };
    case GET_PREMIUMS_BY_PAYMENT_REF_SUCCESS:
      return { ...state, premiums: action.payload, loading: false };
    case GET_PREMIUMS_BY_PAYMENT_REF_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_PREMIUMS_BY_POLICY_NUMBER_REQUESTED:
      return { ...state, loading: true, success: false };
    case GET_PREMIUMS_BY_POLICY_NUMBER_SUCCESS:
      return { ...state, premiums: action.payload, loading: false };
    case GET_PREMIUMS_BY_POLICY_NUMBER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_PREMIUM_BY_POLICY_NUMBER_REQUESTED:
      return { ...state, loading: true, success: false };
    case GET_PREMIUM_BY_POLICY_NUMBER_SUCCESS:
      return { ...state, premiums: action.payload, loading: false };
    case GET_PREMIUM_BY_POLICY_NUMBER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CLEAR_PREMIUMS:
      return { ...state, premiums: undefined };
    default:
      return state;
  }
};
