import { put, call } from "redux-saga/effects";
import {
  requestCreateUnderwriter,
  requestCreateUnderwriterRates,
  requestGetUnderwritersByOrgID,
  requestGetUnderwriter,
  requestGetUnderwriterRatesByOrgId,
  requestGetUnderwriterRatesById,
  requestCloneUnderwriterRatesById,
  requestUpdateUnderwriterRates,
  requestUpdateUnderwriter,
  requestGetUnderwriterRatesByUnderwriterId,
  requestDeleteUnderwriterRates,
} from "../requests/underwriter";
import {
  createUnderwriterSuccess,
  createUnderwriterFailure,
  createUnderwriterRatesSuccess,
  createUnderwriterRatesFailure,
  getUnderwritersByOrgIDSuccess,
  getUnderwritersByOrgIDFailure,
  getUnderwriterSuccess,
  getUnderwriterFailure,
  getUnderwriterRatesByOrgIdSuccess,
  getUnderwriterRatesByOrgIdFailure,
  getUnderwriterRatesByIdSuccess,
  getUnderwriterRatesByIdFailure,
  getUnderwriterRatesByUnderwriterIdSuccess,
  getUnderwriterRatesByUnderwriterIdFailure,
  cloneUnderwriterRatesByIdSuccess,
  cloneUnderwriterRatesByIdFailure,
  updateUnderwriterSuccess,
  updateUnderwriterFailure,
  updateUnderwriterRatesSuccess,
  updateUnderwriterRatesFailure,
  deleteUnderwriterRatesSuccess,
  deleteUnderwriterRatesFailure,
} from "../../ducks/underwriter";

export function* handleCreateUnderwriter(action) {
  try {
    const response = yield call(requestCreateUnderwriter, action.payload);
    const { data } = response;
    yield put(createUnderwriterSuccess(data.response));
  } catch (err) {
    yield put(createUnderwriterFailure(err.message));
  }
}

export function* handleCreateUnderwriterRates(action) {
  try {
    const response = yield call(requestCreateUnderwriterRates, action.payload);
    const { data } = response;
    yield put(createUnderwriterRatesSuccess(data.response));
  } catch (err) {
    yield put(createUnderwriterRatesFailure(err.message));
  }
}

export function* handleGetUnderwriter(action) {
  try {
    const response = yield call(requestGetUnderwriter, action.id);
    const { data } = response;
    yield put(getUnderwriterSuccess(data.response));
  } catch (err) {
    yield put(getUnderwriterFailure(err.message));
  }
}

export function* handleGetUnderwritersByOrgID(action) {
  try {
    const response = yield call(requestGetUnderwritersByOrgID, action.id);
    const { data } = response;
    yield put(getUnderwritersByOrgIDSuccess(data.response));
  } catch (err) {
    yield put(getUnderwritersByOrgIDFailure(err.message));
  }
}

export function* handleGetUnderwriterRatesByOrgId(action) {
  try {
    const response = yield call(requestGetUnderwriterRatesByOrgId, action.id);
    const { data } = response;
    yield put(getUnderwriterRatesByOrgIdSuccess(data.response));
  } catch (err) {
    yield put(getUnderwriterRatesByOrgIdFailure(err.message));
  }
}

export function* handleGetUnderwriterRatesById(action) {
  try {
    const response = yield call(requestGetUnderwriterRatesById, action.id);
    const { data } = response;
    yield put(getUnderwriterRatesByIdSuccess(data.response));
  } catch (err) {
    yield put(getUnderwriterRatesByIdFailure(err.message));
  }
}

export function* handleCloneUnderwriterRatesById(action) {
  try {
    console.log(action);
    const response = yield call(requestCloneUnderwriterRatesById, action);
    const { data } = response;
    yield put(cloneUnderwriterRatesByIdSuccess(data.response));
  } catch (err) {
    yield put(cloneUnderwriterRatesByIdFailure(err.message));
  }
}

export function* handleGetUnderwriterRatesByUnderwriterId(action) {
  try {
    const response = yield call(
      requestGetUnderwriterRatesByUnderwriterId,
      action.id
    );
    const { data } = response;
    yield put(getUnderwriterRatesByUnderwriterIdSuccess(data.response));
  } catch (err) {
    yield put(getUnderwriterRatesByUnderwriterIdFailure(err.message));
  }
}

export function* handleUpdateUnderwriter(action) {
  try {
    const response = yield call(requestUpdateUnderwriter, action.payload);
    const { data } = response;
    yield put(updateUnderwriterSuccess(data.response));
  } catch (err) {
    yield put(updateUnderwriterFailure(err.message));
  }
}

export function* handleUpdateUnderwriterRates(action) {
  try {
    const response = yield call(requestUpdateUnderwriterRates, action.payload);
    const { data } = response;
    yield put(updateUnderwriterRatesSuccess(data.response));
  } catch (err) {
    yield put(updateUnderwriterRatesFailure(err.message));
  }
}

export function* handleDeleteUnderwriterRates(action) {
  try {
    const response = yield call(requestDeleteUnderwriterRates, action.payload);
    const { data } = response;
    yield put(deleteUnderwriterRatesSuccess(data.response));
  } catch (err) {
    yield put(deleteUnderwriterRatesFailure(err.message));
  }
}
