import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { forgotPassword } from "../../redux/saga/requests/user";
import { FailureAlert } from "../../components/CustomAlerts";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader";

const ResetPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);

  const resetSubmit = () => {
    if (email === null || email.trim() === "") {
      setErrorMessage("Email is required.");
      setErrorAlert(true);
      setLoading(false);
      return;
    }

    // var re = new RegExp(
    //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    // );

    // if (re.test(passwordNew)) {
    // } else {
    //   setErrorMessage(
    //     "Invalid New Password: Your new password must be between 8 and 64 characters, must contain at least one uppercase letter, one lowercase letter and one special character (e.g. ! @ # ?)."
    //   );
    //   setErrorAlert(true);
    //    setLoading(false);
    //   return false;
    // }

    forgotPassword({ email })
      .then((data) => {
        if (
          data.CodeDeliveryDetails.AttributeName === "email" &&
          data.CodeDeliveryDetails.DeliveryMedium === "EMAIL"
        ) {
          handleLambdaSubmit();
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setErrorAlert(true);
        setLoading(false);
      });
  };

  const handleLambdaSubmit = () => {
    history.push(`/verification`);
  };

  const toggleFailure = () => {
    setErrorAlert(!errorAlert);
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
        <p className="lead">Enter your email to reset your password.</p>
      </div>

      <FailureAlert
        isOpen={errorAlert}
        toggle={toggleFailure}
        message={errorMessage}
      />

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormGroup>
              <div className="text-center mt-3">
                {loading ? (
                  <Loader height={"vh-5"} />
                ) : (
                  <Button
                    color="primary"
                    size="lg"
                    onClick={() => resetSubmit()}
                  >
                    Verify
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ResetPassword;
