export const CREATE_ORG_REQUESTED = "CREATE_ORGANISATION_REQUESTED";
export const CREATE_ORG_SUCCESS = "CREATE_ORGANISATION_SUCCESS";
export const CREATE_ORG_FALIURE = "CREATE_ORGANISATION_FALIURE";
export const UPDATE_ORG_REQUESTED = "UPDATE_ORGANISATION_REQUESTED";
export const UPDATE_ORG_SUCCESS = "UPDATE_ORGANISATION_SUCCESS";
export const UPDATE_ORG_FAILURE = "UPDATE_ORGANISATION_FAILURE";
export const UPDATE_MYORG_REQUESTED = "UPDATE_MY_ORGANISATION_REQUESTED";
export const UPDATE_MYORG_SUCCESS = "UPDATE_MY_ORGANISATION_SUCCESS";
export const UPDATE_MYORG_FAILURE = "UPDATE_MY_ORGANISATION_FAILURE";
export const GET_ORG_REQUESTED = "GET_ORGANISATION_REQUESTED";
export const GET_ORG_SUCCESS = "GET_ORGANISATION_SUCCESS";
export const GET_ORG_FALIURE = "GET_ORGANISATION_FAILURE";
export const GET_MY_ORG_REQUESTED = "GET_MY_ORGANISATION_REQUESTED";
export const GET_MY_ORG_SUCCESS = "GET_MY_ORGANISATION_SUCCESS";
export const GET_MY_ORG_FAILURE = "GET_MY_ORGANISATION_FAILURE";
export const GET_ORGS_REQUESTED = "GET_ORGANISATIONS_REQUESTED";
export const GET_ORGS_SUCCESS = "GET_ORGANISATIONS_SUCCESS";
export const GET_ORGS_FALIURE = "GET_ORGANISATIONS_FALIURE";
export const GET_ALL_ORGS_REQUESTED = "GET_ALL_ORGANISATIONS_REQUESTED";
export const GET_ALL_ORGS_SUCCESS = "GET_ALL_ORGANISATIONS_SUCCESS";
export const GET_ALL_ORGS_FALIURE = "GET_ALL_ORGANISATIONS_FALIURE";
export const SET_DASHBOARD_REQUESTED = "SET_DASHBOARD_REQUESTED";
export const GET_DASHBOARD_REQUESTED = "GET_DASHBOARD_REQUESTED";

export const createOrg = (payload) => ({
  type: CREATE_ORG_REQUESTED,
  payload,
});

export const createOrgFailure = (payload) => ({
  type: CREATE_ORG_FALIURE,
  payload,
});

export const createOrgSuccess = (payload) => ({
  type: CREATE_ORG_SUCCESS,
  payload,
});

export const updateOrg = (payload) => ({
  type: UPDATE_ORG_REQUESTED,
  payload,
});

export const updateOrgSuccess = (payload) => ({
  type: UPDATE_ORG_SUCCESS,
  payload,
});

export const updateOrgFailure = (payload) => ({
  type: UPDATE_ORG_FAILURE,
  payload,
});

export const updateMyOrg = (payload) => ({
  type: UPDATE_MYORG_REQUESTED,
  payload,
});

export const updateMyOrgSuccess = (payload) => ({
  type: UPDATE_MYORG_SUCCESS,
  payload,
});

export const updateMyOrgFailure = (payload) => ({
  type: UPDATE_MYORG_FAILURE,
  payload,
});

export const getOrg = (payload) => ({
  type: GET_ORG_REQUESTED,
  payload,
});

export const getOrgSuccess = (payload) => ({
  type: GET_ORG_SUCCESS,
  payload,
});

export const getOrgFailure = (payload) => ({
  type: GET_ORG_FALIURE,
  payload,
});

export const getMyOrg = (payload) => ({
  type: GET_MY_ORG_REQUESTED,
  payload,
});

export const getMyOrgSuccess = (payload) => ({
  type: GET_MY_ORG_SUCCESS,
  payload,
});

export const getMyOrgFailure = (payload) => ({
  type: GET_MY_ORG_FAILURE,
  payload,
});

export const getOrgs = (id) => ({
  type: GET_ORGS_REQUESTED,
  id,
});

export const getOrgsSuccess = (payload) => ({
  type: GET_ORGS_SUCCESS,
  payload,
});

export const getOrgsFailure = (payload) => ({
  type: GET_ORGS_FALIURE,
  payload,
});

export const getAllOrgs = () => ({
  type: GET_ALL_ORGS_REQUESTED,
});

export const setDashboard = (payload) => ({
  type: SET_DASHBOARD_REQUESTED,
  payload,
});

export const getDashboard = () => ({
  type: GET_DASHBOARD_REQUESTED,
});

const initialState = {
  myOrganisation: undefined,
  dashboard: undefined,
  organisation: undefined,
  organisations: undefined,
  loading: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORG_REQUESTED:
      return { ...state, loading: true };
    case CREATE_ORG_SUCCESS:
      return { ...state, organisation: action.payload, loading: false };
    case CREATE_ORG_FALIURE:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_ORG_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_ORG_SUCCESS:
      return { ...state, organisation: action.payload, loading: false };
    case UPDATE_ORG_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_MYORG_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_MYORG_SUCCESS:
      return { ...state, myOrganisation: action.payload, loading: false };
    case UPDATE_MYORG_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case GET_ORG_REQUESTED:
      return { ...state, loading: true };
    case GET_ORG_SUCCESS:
      return { ...state, organisation: action.payload, loading: false };
    case GET_ORG_FALIURE:
      return { ...state, error: action.payload, loading: false };
    case GET_MY_ORG_REQUESTED:
      return { ...state, loading: true };
    case GET_MY_ORG_SUCCESS:
      return { ...state, myOrganisation: action.payload, loading: false };
    case GET_MY_ORG_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case GET_ORGS_REQUESTED:
      return { ...state, loading: true };
    case GET_ORGS_SUCCESS:
      return { ...state, organisations: action.payload, loading: false };
    case GET_ORGS_FALIURE:
      return { ...state, error: action.payload, loading: false };
    case GET_ALL_ORGS_REQUESTED:
      return { ...state, loading: true };
    case GET_ALL_ORGS_SUCCESS:
      return { ...state, organisations: action.payload, loading: false };
    case GET_ALL_ORGS_FALIURE:
      return { ...state, error: action.payload, loading: false };
    case SET_DASHBOARD_REQUESTED:
      return { ...state, dashboard: action.payload };
    case GET_DASHBOARD_REQUESTED:
      return state;
    default:
      return state;
  }
};
