import axios from "axios";

export function requestCreateFolder(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/folder/create`,
    data,
  });
}

export function requestGetOrgFolders(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/folder/organization/${data}`,
  });
}

export function requestGetPolicyFolders(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/folder/policy/${data}`,
  });
}

export function requestUploadDocument(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/folder/add/file`,
    data,
  });
}

export function requestUpdateFolder(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/folder/update`,
    data,
  });
}

export function requestDeleteFolder(data) {
  return axios.request({
    method: "delete",
    url: `${process.env.REACT_APP_DOMAIN}/folder/${data.folderId}`,
  });
}

//****************************************//
//************* MEMBERSHIP ***************//
//****************************************//

export function requestUploadMembershipData(data) {
  var formdata = new FormData();

  var requestOptions = {
    method: "POST",
    body: formdata.append("Key", data.file, data.meta.name),
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_DOMAIN}/policy/application/upload`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.error(error);
    });

  //===============
  //     var formdata = new FormData();
  // formdata.append("Key", fileInput.files[0], "DIAMOND - CHODIA_0131 - JUNE 2022.xlsx");

  // var requestOptions = {
  //   method: 'POST',
  //   body: formdata,
  //   redirect: 'follow'
  // };

  // fetch("https://prod.betterlife-sa.co.za/policy/application/upload", requestOptions)
  //   .then(response => response.text())
  //   .then(result => console.log(result))
  //   .catch(error => console.log('error', error));
  //===============
}

const API_BASE_URL =
  "https://YOUR_API_ID.execute-api.REGION.amazonaws.com/STAGE";

// To upload a file:

// To read a file:
const readFile = async (fileName) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/read`, {
      fileName: fileName,
    });

    if (response.data) {
      console.log("File Content: ", response.data);
      return response.data;
    }
  } catch (error) {
    console.error("Error reading file:", error);
  }
};
