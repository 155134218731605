import axios from "axios";
import { Amplify, Auth } from "aws-amplify";

export function requestCreateUser(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/user/create`,
    data,
  });
}

export function requestGetUsers(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/users/${data}`,
  });
}

export function requestGetUserByEmail(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/user/profile/${data}`,
  });
}

export function requestUpdateUser(data) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_DOMAIN}/user/update`,
    data,
  });
}

export function requestDeleteUser(data) {
  return axios.request({
    method: "delete",
    url: `${process.env.REACT_APP_DOMAIN}/user/${data}`,
  });
}

export async function loginUser(body = {}) {
  Amplify.configure({
    Auth: {
      mandatorySignId: true,
      region: "eu-west-2",
      userPoolId: process.env.REACT_APP_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_POOL_CLIENT_ID,
    },
  });
  const user = await Auth.signIn(body.email, body.password);
  return user;
}

export async function registerCognitoUser(body = {}) {
  Amplify.configure({
    Auth: {
      mandatorySignId: true,
      region: "eu-west-2",
      userPoolId: process.env.REACT_APP_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_POOL_CLIENT_ID,
    },
  });

  const user = await Auth.signUp({
    email: body.email,
    password: body.password,
    attributes: {},
  });
  return user;
}

export async function forgotPassword(body = {}) {
  Amplify.configure({
    Auth: {
      mandatorySignId: true,
      region: "eu-west-2",
      userPoolId: process.env.REACT_APP_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_POOL_CLIENT_ID,
    },
  });
  const response = await Auth.forgotPassword(body.email);
  return response;
}

export async function resetPassword(body = {}) {
  Amplify.configure({
    Auth: {
      mandatorySignId: true,
      region: "eu-west-2",
      userPoolId: process.env.REACT_APP_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_POOL_CLIENT_ID,
    },
  });
  const response = await Auth.forgotPasswordSubmit(
    body.email,
    body.verificationCode,
    body.passwordNew
  );
  return response;
}

export async function signOut() {
  const response = await Auth.signOut({ global: true });
  return response;
}
