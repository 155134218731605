export const GET_EMAIL_TEMPLATES_REQUESTED = "GET_EMAIL_TEMPLATES_REQUESTED";
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS";
export const CREATE_SMS_REQUESTED = "CREATE_SMS_REQUESTED";
export const CREATE_SMS_SUCCESS = "CREATE_SMS_SUCCESS";
export const CREATE_EMAIL_REQUESTED = "CREATE_EMAIL_REQUESTED";
export const CREATE_EMAIL_SUCCESS = "CREATE_EMAIL_SUCCESS";
export const CREATE_NOTIFICATION_REQUESTED = "CREATE_NOTIFICATION_REQUESTED";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const GET_POLICY_NOTIFICATIONS_REQUESTED =
  "GET_POLICY_NOTIFICATIONS_REQUESTED";
export const GET_POLICY_NOTIFICATIONS_SUCCESS =
  "GET_POLICY_NOTIFICATIONS_SUCCESS";
export const GET_SMS_REPORT_REQUESTED = "GET_SMS_REPORT_REQUESTED";
export const GET_SMS_REPORT_SUCCESS = "GET_SMS_REPORT_SUCCESS";
export const GET_EMAIL_REPORT_REQUESTED = "GET_EMAIL_REPORT_REQUESTED";
export const GET_EMAIL_REPORT_SUCCESS = "GET_EMAIL_REPORT_SUCCESS";
export const GET_IN_APP_NOTIFICATIONS_REQUESTED =
  "GET_IN_APP_NOTIFICATIONS_REQUESTED";
export const GET_IN_APP_NOTIFICATIONS_SUCCESS =
  "GET_IN_APP_NOTIFICATIONS_SUCCESS";
export const SEND_PDF_EMAIL_REQUESTED = "SEND_PDF_EMAIL_REQUESTED";
export const SEND_PDF_EMAIL_SUCCESS = "SEND_PDF_EMAIL_SUCCESS";
export const SEND_CSV_EMAIL_REQUESTED = "SEND_CSV_EMAIL_REQUESTED";
export const SEND_CSV_EMAIL_SUCCESS = "SEND_CSV_EMAIL_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_REPORT = "CLEAR_REPORT";

export const getEmailTemplates = (payload) => ({
  type: GET_EMAIL_TEMPLATES_REQUESTED,
  payload,
});
export const getEmailTemplatesSuccess = (payload) => ({
  type: GET_EMAIL_TEMPLATES_SUCCESS,
  payload,
});

export const createSendEmailNotification = (payload) => ({
  type: CREATE_EMAIL_REQUESTED,
  payload,
});
export const createSendEmailNotificationSuccess = (payload) => ({
  type: CREATE_EMAIL_SUCCESS,
  payload,
});

export const createSendSMSNotification = (payload) => ({
  type: CREATE_SMS_REQUESTED,
  payload,
});
export const createSendSMSNotificationSuccess = (payload) => ({
  type: CREATE_SMS_SUCCESS,
  payload,
});

export const createNotification = (payload) => ({
  type: CREATE_NOTIFICATION_REQUESTED,
  payload,
});
export const createNotificationSuccess = (payload) => ({
  type: CREATE_NOTIFICATION_SUCCESS,
  payload,
});

export const getPolicyNotifications = (payload) => ({
  type: GET_POLICY_NOTIFICATIONS_REQUESTED,
  payload,
});
export const getPolicyNotificationsSuccess = (payload) => ({
  type: GET_POLICY_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getNotificationsInApp = (payload) => ({
  type: GET_IN_APP_NOTIFICATIONS_REQUESTED,
  payload,
});
export const getNotificationsInAppSuccess = (payload) => ({
  type: GET_IN_APP_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getSmsReport = (payload) => ({
  type: GET_SMS_REPORT_REQUESTED,
  payload,
});

export const getSmsReportSuccess = (payload) => ({
  type: GET_SMS_REPORT_SUCCESS,
  payload,
});

export const getEmailReport = (payload) => ({
  type: GET_EMAIL_REPORT_REQUESTED,
  payload,
});

export const getEmailReportSuccess = (payload) => ({
  type: GET_EMAIL_REPORT_SUCCESS,
  payload,
});

export const sendPDFEmail = (payload) => ({
  type: SEND_PDF_EMAIL_REQUESTED,
  payload,
});

export const sendPDFEmailSuccess = (payload) => ({
  type: SEND_PDF_EMAIL_SUCCESS,
  payload,
});

export const sendCSVEmail = (payload) => ({
  type: SEND_CSV_EMAIL_REQUESTED,
  payload,
});

export const sendCSVEmailSuccess = (payload) => ({
  type: SEND_CSV_EMAIL_SUCCESS,
  payload,
});

export const clearReport = () => ({
  type: CLEAR_REPORT,
});

export const getError = (error) => ({
  type: GET_ERRORS,
  error,
});

const initialState = {
  sms: undefined,
  email: undefined,
  emailTemplates: undefined,
  emailRespone: undefined,
  policyNotifications: undefined,
  notifications: undefined,
  inAppNotifications: undefined,
  loading: false,
  loadingLogs: false,
  saved: false,
  report: undefined,
  reportSms: undefined,
  reportEmail: undefined,
  success: "",
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_POLICY_NOTIFICATIONS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: action.payload,
      };
    case CREATE_SMS_SUCCESS:
      return {
        ...state,
        sms: action.payload,
        success: "SMS has been sent successfully",
        saved: false,
      };
    case CREATE_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.payload,
        success: "Email has been sent successfully",
        saved: false,
      };
    case GET_POLICY_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        policyNotifications: action.payload,
        loading: false,
      };
    case GET_IN_APP_NOTIFICATIONS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_IN_APP_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        inAppNotifications: action.payload,
        loading: false,
      };
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        saved: true,
      };
    case GET_SMS_REPORT_REQUESTED:
      return {
        ...state,
        loadingLogs: true,
      };
    case GET_SMS_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload,
        reportSms: action.payload,
        loadingLogs: false,
      };
    case GET_EMAIL_REPORT_REQUESTED:
      return {
        ...state,
        loadingLogs: true,
      };
    case GET_EMAIL_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload.response,
        reportEmail: action.payload.response,
        loadingLogs: false,
      };
    case SEND_PDF_EMAIL_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case SEND_PDF_EMAIL_SUCCESS:
      return {
        ...state,
        emailRespone: action.payload.response,
        loading: false,
      };
    case SEND_CSV_EMAIL_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case SEND_CSV_EMAIL_SUCCESS:
      return {
        ...state,
        emailRespone: action.payload.response,
        loading: false,
      };
    case CLEAR_REPORT:
      return {
        report: undefined,
        reportSms: undefined,
        reportEmail: undefined,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
        loadingLogs: false,
        loading: false,
      };
    default:
      return state;
  }
}
