import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Badge, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import betterlifelogo from "../assets/img/brands/betterlife-soft05.png";
import {
  dashboard as dashboardRoutes,
  nonFSPdashboard as nonFSPdashboardRoutes,
} from "../routes/index";
import avatar from "../assets/img/avatars/blank-avatar.jpg";
import { getUsers } from "../redux/ducks/user";

const GetRoute = () => {
  const orgData = useSelector((state) => state.organisation.myOrganisation);
  if (orgData !== null) {
    if (orgData?.organizationOperation?.sellerType === "Non-FSP") {
      return nonFSPdashboardRoutes;
    } else {
      return dashboardRoutes;
    }
  }
};

const initOpenRoutes = (location, curRoutes) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;
  //const currentRoutes = GetRoute();
  let _routes = {};

  curRoutes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

function transformText(str) {
  return str.replace(/\b\w+/g, function (s) {
    return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
  });
}

const Sidebar = ({ location, sidebar }) => {
  const curRoutes = GetRoute();
  const dispatch = useDispatch();
  const [openRoutes, setOpenRoutes] = useState(() =>
    initOpenRoutes(location, curRoutes)
  );
  const [logo, setLogo] = useState(undefined);
  const [currentRoutes, setCurrentRoutes] = useState(undefined);
  const data = useSelector((state) => state.user.profile);
  const orgData = useSelector((state) => state.organisation.myOrganisation);

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  useEffect(() => {
    dispatch(getUsers(orgData.id));
    if (orgData.organizationOperation.sellerType === "Non-FSP") {
      setCurrentRoutes(nonFSPdashboardRoutes);
    } else {
      setCurrentRoutes(dashboardRoutes);
    }
    //TO DO: Enbale once logo customisation is done
    // if (orgData !== undefined) {
    //   console.log("orgData.organizationName: ", orgData.organizationName);
    //   orgData.organizationName === "Choice Benefit"
    //     ? setLogo(
    //         "https://bls-assets.s3.eu-west-2.amazonaws.com/organisation/choicebenefits.png"
    //       )
    //     : setLogo(
    //         "https://bls-assets.s3.eu-west-2.amazonaws.com/organisation/BIC-Logo.png"
    //       );
    // }
  }, [orgData]);

  return (
    <nav
      className={classNames(
        "sidebar",
        sidebar.isOpen || "toggled",
        !sidebar.isOnRight || "sidebar-right"
      )}
    >
      <div className="sidebar-content">
        <a
          className={classNames(
            "sidebar-brand",
            !sidebar.isOnRight || "text-right"
          )}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          href="/"
        >
          {logo !== undefined ? (
            <img
              src={logo}
              className="img-fluid"
              alt="Company Logo"
              style={{
                width: "70%",
              }}
            />
          ) : (
            <img
              src={betterlifelogo}
              className="img-fluid"
              alt="Company Logo"
              style={{
                width: "70%",
              }}
            />
          )}
        </a>

        <div className="sidebar-user">
          <img
            src={avatar}
            className="img-fluid rounded-circle mb-2"
            alt="Lothar Mohlala"
          />
          <div className="font-weight-bold">
            {data.fullName} {data.surname}
          </div>
          <small>{transformText(data.role.replace("_", " "))}</small>
        </div>
        {currentRoutes && (
          <ul className="sidebar-nav">
            {currentRoutes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        )}
      </div>
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
  }))(Sidebar)
);
