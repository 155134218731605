import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { resetPassword } from "../../redux/saga/requests/user";
import { FailureAlert } from "../../components/CustomAlerts";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/ducks/user";
import { getMyOrg } from "../../redux/ducks/organisation";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader";

const ResetVerification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);

  const resetSubmit = () => {
    setLoading(true);
    if (verificationCode === null || verificationCode.trim() === "") {
      setErrorMessage("Verification Code is required.");
      setErrorAlert(true);
      setLoading(false);
      return;
    }

    if (email === null || email.trim() === "") {
      setErrorMessage("Email is required.");
      setErrorAlert(true);
      setLoading(false);
      return;
    }

    if (password === null || password.trim() === "") {
      setErrorMessage("Password is required.");
      setErrorAlert(true);
      setLoading(false);
      return;
    }

    if (confirmPassword === null || confirmPassword.trim() === "") {
      setErrorMessage("Confirmed Password is required.");
      setErrorAlert(true);
      setLoading(false);
      return;
    }

    if (confirmPassword === password) {
      resetPassword({ email, verificationCode, passwordNew: password })
        .then((data) => {
          handleLambdaLogin(data);
        })
        .catch((err) => {
          setErrorMessage(err.message);
          setErrorAlert(true);
          setLoading(false);
        });
    }

    const handleLambdaLogin = async (data) => {
      // const response = await axios({
      //   method: "GET",
      //   url: `${process.env.REACT_APP_PROFILE_ENDPOINT}?user=${data.challengeParam.userAttributes.address}`,
      // });
      // dispatch(setProfile(response.data.Item));
      // dispatch(getMyOrg({ id: response.data.Item.organizationId }));
      // history.push(`/dashboard`);
    };
  };

  const toggle = () => {
    setErrorAlert(!errorAlert);
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
        <p className="lead">
          Please enter the verification code sent to your email address below,
          your email address and a new password.
        </p>
      </div>

      <FailureAlert
        isOpen={errorAlert}
        toggle={toggle}
        message={errorMessage}
      />

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label>Verification Code</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="verificationcode"
                  placeholder="Enter your verification code"
                  onChange={(event) => setVerificationCode(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  autoComplete="off"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>New Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="newpassword"
                  placeholder="Enter your new password"
                  autoComplete="password"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Confirm New Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="confirmnewpassword"
                  placeholder="Confirm your new password"
                  autoComplete="new-password"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </FormGroup>
              <div className="text-center mt-3">
                {loading ? (
                  <Loader height={"vh-5"} />
                ) : (
                  <Button
                    color="primary"
                    size="lg"
                    onClick={() => resetSubmit()}
                  >
                    Reset Password
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ResetVerification;
