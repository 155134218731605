import { takeLatest } from "redux-saga/effects";
import {
  handleCreateOrganisation,
  handleGetOrganisation,
  handleGetMyOrganisation,
  handleGetOrganisationsByOrgId,
  handleUpdateOrganisation,
  handleUpdateMyOrganisation,
} from "./handlers/organisation";
import {
  handleCreateUser,
  handleGetUsers,
  handleGetUser,
  handleUpdateUser,
  handleDeleteUser,
} from "./handlers/user";
import {
  handleCreateUnderwriter,
  handleGetUnderwritersByOrgID,
  handleCreateUnderwriterRates,
  handleGetUnderwriterRatesByOrgId,
  handleGetUnderwriterRatesById,
  handleGetUnderwriter,
  handleUpdateUnderwriter,
  handleUpdateUnderwriterRates,
  handleGetUnderwriterRatesByUnderwriterId,
  handleDeleteUnderwriterRates,
  handleCloneUnderwriterRatesById,
} from "./handlers/underwriter";
import {
  handleCreateScheme,
  handleGetSchemesByOrgId,
  handleGetScheme,
  handleUpdateScheme,
  handleDeleteScheme,
} from "./handlers/scheme";
import {
  handleCreatePolicy,
  handleGetPolicyById,
  handleGetPoliciesBySchemeId,
  handleUpdatePolicy,
  handleDeletePolicy,
  handleUpdatePolicyStatus,
} from "./handlers/policy";
import {
  handleCreatePolicyFolder,
  handleGetPolicyFolders,
  handleCreateOrgFolder,
  handleGetOrgFolders,
  handleDeletePolicyFolder,
  handleUpdatePolicyFolder,
  handleUpdateOrgFolder,
  handleDeleteOrgFolder,
} from "./handlers/media";
import {
  handleSendSMSNotification,
  handleSendEmailNotification,
  handleGetEmailTemplates,
  handleCreateNotification,
  handleGetPolicyNotifications,
  handleGetInAppNotifications,
  handleGetSmsReport,
  handleGetEmailReport,
  handleSendPDFEmailNotification,
  handleSendCSVEmailNotification,
} from "./handlers/notifications";
import {
  handleCreatePremium,
  handleUpdatePremium,
  handleUpdateSinglePremium,
  handleGetPremiumByMemberId,
  handleGetPremiumByAccountNumber,
  handleGetPremiumByPaymentRef,
  handleGetPremiumByPolicyNumber,
  handleGetsSinglePremiumByPolicyNumber,
} from "./handlers/premiums";
import {
  handleGetSafricanPremiumBySchemeNo,
  handleGetKingPricePremiumBySchemeNo,
  handleGetKingPriceNewBusinessBySchemeNo,
  handleGetKingPriceAmendmentsBySchemeNo,
} from "./handlers/reports";
import { handleGetAuditLogs } from "./handlers/audit";
import {
  CREATE_ORG_REQUESTED,
  GET_ORG_REQUESTED,
  GET_MY_ORG_REQUESTED,
  GET_ORGS_REQUESTED,
  UPDATE_ORG_REQUESTED,
  UPDATE_MYORG_REQUESTED,
} from "../ducks/organisation";
import {
  CREATE_USER_REQUESTED,
  GET_USERS_REQUESTED,
  GET_USER_REQUESTED,
  UPDATE_USER_REQUESTED,
  DELETE_USER_REQUESTED,
} from "../ducks/user";
import {
  CREATE_UNDERWRITER_REQUESTED,
  CREATE_UNDERWRITER_RATES_REQUESTED,
  GET_UNDERWRITER_REQUESTED,
  GET_UNDERWRITERS_ORGID_REQUESTED,
  GET_UNDERWRITERS_RATES_ORGID_REQUESTED,
  GET_UNDERWRITER_RATES_ID_REQUESTED,
  UPDATE_UNDERWRITER_REQUESTED,
  UPDATE_UNDERWRITER_RATES_REQUESTED,
  GET_UNDERWRITER_RATES_UNDERWRITERID_REQUESTED,
  DELETE_UNDERWRITER_RATES_REQUESTED,
  CLONE_UNDERWRITER_RATES_ID_REQUESTED,
} from "../ducks/underwriter";
import {
  CREATE_SCHEME_REQUESTED,
  GET_SCHEME_REQUESTED,
  GET_SCHEMES_ORGID_REQUESTED,
  UPDATE_SCHEME_REQUESTED,
  DELETE_SCHEME_REQUESTED,
} from "../ducks/scheme";
import {
  CREATE_POLICY_REQUESTED,
  GET_POLICY_REQUESTED,
  GET_POLICIES_SCHEMEID_REQUESTED,
  UPDATE_POLICY_REQUESTED,
  DELETE_POLICY_REQUESTED,
  UPDATE_POLICY_STATUS_REQUESTED,
} from "../ducks/policy";
import {
  CREATE_POLICY_FOLDER_REQUESTED,
  GET_POLICY_FOLDERS_REQUESTED,
  UPDATE_POLICY_FOLDER_REQUESTED,
  DELETE_POLICY_FOLDER_REQUESTED,
} from "../ducks/media_policy";
import {
  CREATE_ORG_FOLDER_REQUESTED,
  GET_ORG_FOLDERS_REQUESTED,
  UPDATE_ORG_FOLDER_REQUESTED,
  DELETE_ORG_FOLDER_REQUESTED,
} from "../ducks/media_org";
import {
  GET_EMAIL_TEMPLATES_REQUESTED,
  CREATE_SMS_REQUESTED,
  CREATE_EMAIL_REQUESTED,
  CREATE_NOTIFICATION_REQUESTED,
  GET_POLICY_NOTIFICATIONS_REQUESTED,
  GET_IN_APP_NOTIFICATIONS_REQUESTED,
  GET_SMS_REPORT_REQUESTED,
  GET_EMAIL_REPORT_REQUESTED,
  SEND_PDF_EMAIL_REQUESTED,
  SEND_CSV_EMAIL_REQUESTED,
} from "../ducks/notifications";
import {
  CREATE_PREMIUM_REQUESTED,
  UPDATE_PREMIUM_REQUESTED,
  UPDATE_SINGLE_PREMIUM_REQUESTED,
  GET_PREMIUMS_BY_MEMBER_ID_REQUESTED,
  GET_PREMIUMS_BY_ACCOUNT_NUMBER_REQUESTED,
  GET_PREMIUMS_BY_PAYMENT_REF_REQUESTED,
  GET_PREMIUMS_BY_POLICY_NUMBER_REQUESTED,
  GET_PREMIUM_BY_POLICY_NUMBER_REQUESTED,
} from "../ducks/premiums";
import {
  GET_SAFRICAN_PREMIUMS_REQUESTED,
  GET_KING_PRICE_PREMIUMS_REQUESTED,
  GET_KING_PRICE_NEW_BUSINESS_REQUESTED,
  GET_KING_PRICE_AMENDMENTS_REQUESTED,
} from "../ducks/reports";
import { GET_AUDIT_LOGS_REQUESTED } from "../ducks/audit";

export function* watchSaga() {
  // Organisation watchers
  yield takeLatest(CREATE_ORG_REQUESTED, handleCreateOrganisation);
  yield takeLatest(GET_ORG_REQUESTED, handleGetOrganisation);
  yield takeLatest(GET_MY_ORG_REQUESTED, handleGetMyOrganisation);
  yield takeLatest(GET_ORGS_REQUESTED, handleGetOrganisationsByOrgId);
  yield takeLatest(UPDATE_ORG_REQUESTED, handleUpdateOrganisation);
  yield takeLatest(UPDATE_MYORG_REQUESTED, handleUpdateMyOrganisation);
  // User watchers
  yield takeLatest(CREATE_USER_REQUESTED, handleCreateUser);
  yield takeLatest(GET_USERS_REQUESTED, handleGetUsers);
  yield takeLatest(GET_USER_REQUESTED, handleGetUser);
  yield takeLatest(UPDATE_USER_REQUESTED, handleUpdateUser);
  yield takeLatest(DELETE_USER_REQUESTED, handleDeleteUser);
  // Underwriter watchers
  yield takeLatest(CREATE_UNDERWRITER_REQUESTED, handleCreateUnderwriter);
  yield takeLatest(
    GET_UNDERWRITERS_ORGID_REQUESTED,
    handleGetUnderwritersByOrgID
  );
  yield takeLatest(
    CREATE_UNDERWRITER_RATES_REQUESTED,
    handleCreateUnderwriterRates
  );
  yield takeLatest(
    GET_UNDERWRITERS_RATES_ORGID_REQUESTED,
    handleGetUnderwriterRatesByOrgId
  );
  yield takeLatest(
    GET_UNDERWRITER_RATES_ID_REQUESTED,
    handleGetUnderwriterRatesById
  );
  yield takeLatest(GET_UNDERWRITER_REQUESTED, handleGetUnderwriter);
  yield takeLatest(UPDATE_UNDERWRITER_REQUESTED, handleUpdateUnderwriter);
  yield takeLatest(
    UPDATE_UNDERWRITER_RATES_REQUESTED,
    handleUpdateUnderwriterRates
  );
  yield takeLatest(
    GET_UNDERWRITER_RATES_UNDERWRITERID_REQUESTED,
    handleGetUnderwriterRatesByUnderwriterId
  );
  yield takeLatest(
    DELETE_UNDERWRITER_RATES_REQUESTED,
    handleDeleteUnderwriterRates
  );
  yield takeLatest(
    CLONE_UNDERWRITER_RATES_ID_REQUESTED,
    handleCloneUnderwriterRatesById
  );
  // Scheme watchers
  yield takeLatest(CREATE_SCHEME_REQUESTED, handleCreateScheme);
  yield takeLatest(GET_SCHEMES_ORGID_REQUESTED, handleGetSchemesByOrgId);
  yield takeLatest(GET_SCHEME_REQUESTED, handleGetScheme);
  yield takeLatest(CREATE_POLICY_REQUESTED, handleCreatePolicy);
  yield takeLatest(GET_POLICY_REQUESTED, handleGetPolicyById);
  yield takeLatest(
    GET_POLICIES_SCHEMEID_REQUESTED,
    handleGetPoliciesBySchemeId
  );
  yield takeLatest(UPDATE_SCHEME_REQUESTED, handleUpdateScheme);
  yield takeLatest(UPDATE_POLICY_REQUESTED, handleUpdatePolicy);
  yield takeLatest(UPDATE_POLICY_STATUS_REQUESTED, handleUpdatePolicyStatus);
  yield takeLatest(DELETE_SCHEME_REQUESTED, handleDeleteScheme);
  yield takeLatest(DELETE_POLICY_REQUESTED, handleDeletePolicy);
  // Media watchers
  yield takeLatest(CREATE_ORG_FOLDER_REQUESTED, handleCreateOrgFolder);
  yield takeLatest(GET_ORG_FOLDERS_REQUESTED, handleGetOrgFolders);
  yield takeLatest(UPDATE_ORG_FOLDER_REQUESTED, handleUpdateOrgFolder);
  yield takeLatest(DELETE_ORG_FOLDER_REQUESTED, handleDeleteOrgFolder);
  // Policy watchers
  yield takeLatest(CREATE_POLICY_FOLDER_REQUESTED, handleCreatePolicyFolder);
  yield takeLatest(GET_POLICY_FOLDERS_REQUESTED, handleGetPolicyFolders);
  yield takeLatest(UPDATE_POLICY_FOLDER_REQUESTED, handleUpdatePolicyFolder);
  yield takeLatest(DELETE_POLICY_FOLDER_REQUESTED, handleDeletePolicyFolder);
  // Notifications watchers
  yield takeLatest(CREATE_SMS_REQUESTED, handleSendSMSNotification);
  yield takeLatest(CREATE_EMAIL_REQUESTED, handleSendEmailNotification);
  yield takeLatest(GET_EMAIL_TEMPLATES_REQUESTED, handleGetEmailTemplates);
  yield takeLatest(CREATE_NOTIFICATION_REQUESTED, handleCreateNotification);
  yield takeLatest(
    GET_POLICY_NOTIFICATIONS_REQUESTED,
    handleGetPolicyNotifications
  );
  yield takeLatest(
    GET_IN_APP_NOTIFICATIONS_REQUESTED,
    handleGetInAppNotifications
  );
  yield takeLatest(GET_SMS_REPORT_REQUESTED, handleGetSmsReport);
  yield takeLatest(GET_EMAIL_REPORT_REQUESTED, handleGetEmailReport);

  yield takeLatest(SEND_PDF_EMAIL_REQUESTED, handleSendPDFEmailNotification);
  yield takeLatest(SEND_CSV_EMAIL_REQUESTED, handleSendCSVEmailNotification);
  // Premiums watchers
  yield takeLatest(CREATE_PREMIUM_REQUESTED, handleCreatePremium);
  yield takeLatest(UPDATE_PREMIUM_REQUESTED, handleUpdatePremium);
  yield takeLatest(UPDATE_SINGLE_PREMIUM_REQUESTED, handleUpdateSinglePremium);
  yield takeLatest(
    GET_PREMIUMS_BY_MEMBER_ID_REQUESTED,
    handleGetPremiumByMemberId
  );
  yield takeLatest(
    GET_PREMIUMS_BY_ACCOUNT_NUMBER_REQUESTED,
    handleGetPremiumByAccountNumber
  );
  yield takeLatest(
    GET_PREMIUMS_BY_PAYMENT_REF_REQUESTED,
    handleGetPremiumByPaymentRef
  );
  yield takeLatest(
    GET_PREMIUMS_BY_POLICY_NUMBER_REQUESTED,
    handleGetPremiumByPolicyNumber
  );
  yield takeLatest(
    GET_PREMIUM_BY_POLICY_NUMBER_REQUESTED,
    handleGetsSinglePremiumByPolicyNumber
  );
  // Reports
  yield takeLatest(
    GET_SAFRICAN_PREMIUMS_REQUESTED,
    handleGetSafricanPremiumBySchemeNo
  );
  yield takeLatest(
    GET_KING_PRICE_PREMIUMS_REQUESTED,
    handleGetKingPricePremiumBySchemeNo
  );
  yield takeLatest(
    GET_KING_PRICE_NEW_BUSINESS_REQUESTED,
    handleGetKingPriceNewBusinessBySchemeNo
  );
  yield takeLatest(
    GET_KING_PRICE_AMENDMENTS_REQUESTED,
    handleGetKingPriceAmendmentsBySchemeNo
  );
  //Audit Logs
  yield takeLatest(GET_AUDIT_LOGS_REQUESTED, handleGetAuditLogs);
}
