export const UPLOAD_CLAIM_DOC_REQUESTED = "UPLOAD_CLAIM_DOC_REQUESTED";
export const UPLOAD_CLAIM_DOC_SUCCESS = "UPLOAD_CLAIM_DOC_SUCCESS";
export const UPLOAD_CLAIM_DOC_FAILURE = "UPLOAD_CLAIM_DOC_FAILURE";
export const UPDATE_CLAIM_DOC_REQUESTED = "UPDATE_CLAIM_DOC_REQUESTED";
export const UPDATE_CLAIM_DOC_SUCCESS = "UPDATE_CLAIM_DOC_SUCCESS";
export const UPDATE_CLAIM_DOC_FAILURE = "UPDATE_CLAIM_DOC_FAILURE";
export const DELETE_CLAIM_DOC_REQUESTED = "DELETE_CLAIM_DOC_REQUESTED";
export const DELETE_CLAIM_DOC_SUCCESS = "DELETE_CLAIM_DOC_SUCCESS";
export const DELETE_CLAIM_DOC_FAILURE = "DELETE_CLAIM_DOC_FAILURE";
export const CREATE_CLAIM_FOLDER_REQUESTED = "CREATE_CLAIM_FOLDER_REQUESTED";
export const CREATE_CLAIM_FOLDER_SUCCESS = "CREATE_CLAIM_FOLDER_SUCCESS";
export const CREATE_CLAIM_FOLDER_FAILURE = "CREATE_CLAIM_FOLDER_FAILURE";
export const GET_CLAIM_FOLDERS_REQUESTED = "GET_CLAIM_FOLDERS_REQUESTED";
export const GET_CLAIM_FOLDERS_SUCCESS = "GET_CLAIM_FOLDERS_SUCCESS";
export const GET_CLAIM_FOLDERS_FAILURE = "GET_CLAIM_FOLDERS_FAILURE";
export const UPDATE_CLAIM_FOLDER_REQUESTED = "UPDATE_CLAIM_FOLDER_REQUESTED";
export const UPDATE_CLAIM_FOLDER_SUCCESS = "UPDATE_CLAIM_FOLDER_SUCCESS";
export const UPDATE_CLAIM_FOLDER_FAILURE = "UPDATE_CLAIM_FOLDER_FAILURE";
export const DELETE_CLAIM_FOLDER_REQUESTED = "DELETE_CLAIM_FOLDER_REQUESTED";
export const DELETE_CLAIM_FOLDER_SUCCESS = "DELETE_CLAIM_FOLDER_SUCCESS";
export const DELETE_CLAIM_FOLDER_FAILURE = "DELETE_CLAIM_FOLDER_FAILURE";

// {
//     "id": "fake_data",
//     "policyId": "fake_data",
//     "folders": [
//         {

//             "name": "fake_data",
//             "files": [
//                 {

//                     "name": "fake_data",
//                     "url": "fake_data"
//                 },
//                 {

//                     "name": "fake_data",
//                     "url": "fake_data"
//                 },
//                 {

//                     "name": "fake_data",
//                     "url": "fake_data"
//                 }
//             ]
//         }
//     ]
// }

// /document/claim
// /document/policy
// /document/organization

export const uploadClaimDocument = (payload) => ({
  type: UPLOAD_CLAIM_DOC_REQUESTED,
  payload,
});
export const uploadClaimDocumentSuccess = (payload) => ({
  type: UPLOAD_CLAIM_DOC_SUCCESS,
  payload,
});
export const uploadClaimDocumentFailure = (error) => ({
  type: UPLOAD_CLAIM_DOC_FAILURE,
  error,
});

export const updateClaimDocument = (payload) => ({
  type: UPDATE_CLAIM_DOC_REQUESTED,
  payload,
});
export const updateClaimDocumentSuccess = (payload) => ({
  type: UPDATE_CLAIM_DOC_SUCCESS,
  payload,
});
export const updateClaimDocumentFailure = (error) => ({
  type: UPDATE_CLAIM_DOC_FAILURE,
  error,
});

export const deleteClaimDocument = (payload) => ({
  type: DELETE_CLAIM_DOC_REQUESTED,
  payload,
});
export const deleteClaimDocumentSuccess = (payload) => ({
  type: DELETE_CLAIM_DOC_SUCCESS,
  payload,
});
export const deleteClaimDocumentFailure = (error) => ({
  type: DELETE_CLAIM_DOC_FAILURE,
  error,
});

export const createClaimFolder = (payload) => ({
  type: CREATE_CLAIM_FOLDER_REQUESTED,
  payload,
});
export const createClaimFolderSuccess = (payload) => ({
  type: CREATE_CLAIM_FOLDER_SUCCESS,
  payload,
});
export const createClaimFolderFailure = (error) => ({
  type: CREATE_CLAIM_FOLDER_FAILURE,
  error,
});

export const getClaimFolders = (payload) => ({
  type: GET_CLAIM_FOLDERS_REQUESTED,
  payload,
});
export const getClaimFoldersSuccess = (payload) => ({
  type: GET_CLAIM_FOLDERS_SUCCESS,
  payload,
});
export const getClaimFoldersFailure = (error) => ({
  type: GET_CLAIM_FOLDERS_FAILURE,
  error,
});

export const updateClaimFolder = (payload) => ({
  type: UPDATE_CLAIM_FOLDER_REQUESTED,
  payload,
});
export const updateClaimFolderSuccess = (payload) => ({
  type: UPDATE_CLAIM_FOLDER_SUCCESS,
  payload,
});
export const updateClaimFolderFailure = (error) => ({
  type: UPDATE_CLAIM_FOLDER_FAILURE,
  error,
});

export const deleteClaimFolder = (payload) => ({
  type: DELETE_CLAIM_FOLDER_REQUESTED,
  payload,
});
export const deleteClaimFolderSuccess = (payload) => ({
  type: DELETE_CLAIM_FOLDER_SUCCESS,
  payload,
});
export const deleteClaimFolderFailure = (error) => ({
  type: DELETE_CLAIM_FOLDER_FAILURE,
  error,
});

const initialState = {
  claimsFolders: undefined,
  claimsFolder: undefined,
  claimsdocument: undefined,
  loading: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_CLAIM_DOC_REQUESTED:
      return { ...state, loading: true };
    case UPLOAD_CLAIM_DOC_SUCCESS:
      return { ...state, document: action.payload, loading: false };
    case UPLOAD_CLAIM_DOC_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_CLAIM_DOC_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_CLAIM_DOC_SUCCESS:
      return { ...state, document: action.payload, loading: false };
    case UPDATE_CLAIM_DOC_FAILURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_CLAIM_DOC_REQUESTED:
      return { ...state, loading: true };
    case DELETE_CLAIM_DOC_SUCCESS:
      return { ...state, document: undefined, loading: false };
    case DELETE_CLAIM_DOC_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CREATE_CLAIM_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case CREATE_CLAIM_FOLDER_SUCCESS:
      return { ...state, folder: action.payload, loading: false };
    case CREATE_CLAIM_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case GET_CLAIM_FOLDERS_REQUESTED:
      return { ...state, loading: true };
    case GET_CLAIM_FOLDERS_SUCCESS:
      return { ...state, folders: action.payload, loading: false };
    case GET_CLAIM_FOLDERS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case UPDATE_CLAIM_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case UPDATE_CLAIM_FOLDER_SUCCESS:
      return { ...state, folder: action.payload, loading: false };
    case UPDATE_CLAIM_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    case DELETE_CLAIM_FOLDER_REQUESTED:
      return { ...state, loading: true };
    case DELETE_CLAIM_FOLDER_SUCCESS:
      return { ...state, folder: undefined, loading: false };
    case DELETE_CLAIM_FOLDER_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};
