import { put, call } from "redux-saga/effects";
import {
  getSafricanPremiumsSuccess,
  getSafricanPremiumsFailure,
  getKingPricePremiumsSuccess,
  getKingPricePremiumsFailure,
  getKingPriceNewBusinessSuccess,
  getKingPriceNewBusinessFailure,
  getKingPriceAmendmentsSuccess,
  getKingPriceAmendmentsFailure,
} from "../../ducks/reports";
import {
  requestGetSafricanPremiumBySchemeNo,
  requestGetKingPricePremiumBySchemeNo,
  requestGetKingPriceNewBusinessBySchemeNo,
  requestGetKingPriceAmendmentsBySchemeNo,
} from "../requests/reports";

export function* handleGetSafricanPremiumBySchemeNo(action) {
  try {
    const response = yield call(
      requestGetSafricanPremiumBySchemeNo,
      action.scheme
    );
    yield put(getSafricanPremiumsSuccess({ report: response.data.response }));
  } catch (err) {
    yield put(getSafricanPremiumsFailure({ error: err.message }));
  }
}

export function* handleGetKingPricePremiumBySchemeNo(action) {
  try {
    const response = yield call(
      requestGetKingPricePremiumBySchemeNo,
      action.scheme
    );
    yield put(getKingPricePremiumsSuccess({ report: response.data.response }));
  } catch (err) {
    yield put(getKingPricePremiumsFailure({ error: err.message }));
  }
}

export function* handleGetKingPriceNewBusinessBySchemeNo(action) {
  try {
    const response = yield call(
      requestGetKingPriceNewBusinessBySchemeNo,
      action.scheme
    );
    yield put(
      getKingPriceNewBusinessSuccess({ report: response.data.response })
    );
  } catch (err) {
    yield put(getKingPriceNewBusinessFailure({ error: err.message }));
  }
}

export function* handleGetKingPriceAmendmentsBySchemeNo(action) {
  try {
    const response = yield call(
      requestGetKingPriceAmendmentsBySchemeNo,
      action.scheme
    );
    yield put(
      getKingPriceAmendmentsSuccess({ report: response.data.response })
    );
  } catch (err) {
    yield put(getKingPriceAmendmentsFailure({ error: err.message }));
  }
}
